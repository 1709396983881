import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import cloneDeep from 'lodash/cloneDeep';

// reactstrap components
import {
    Label,
    Button,
    Row,
    Col
} from 'reactstrap';

import client from '../../feathers';
import axios from 'axios';

const SelectedUsers = ({
    notificationOpen,
    getUsers,
    usersData,
    setUsersData
}) => {
    const [ userList, setUserList ] = useState([]);
    const [ selectedUser, setSelectedUser ] = useState({});

    useEffect(() => {
        client.authenticate()
            .then((auth)=>{
                return axios.post(`${client.io.io.uri}fetchUserList`,
                    {},
                    {
                        headers: {
                            'Authorization': auth.accessToken
                        }
                    });
            })
            .then((res) => {
                setUserList(res.data);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
        /* eslint-disable-next-line */
  }, [])

    const removeUser = (index) => {
        let cloneOption = cloneDeep(usersData);
        cloneOption.splice(index, 1);
    
        setUsersData(cloneOption);
    };

    return (  
        <>
            <Label for='userId_selected'>
                Select User
            </Label>
            <Row>
                <Col md='8'>
                    <Field 
                        as='select' 
                        name='userId_selected' 
                        className='form-control'
                        onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        <option value={''}>
                            --
                        </option>
                        {
                            userList.length > 0?
                                userList.map((x, i) => {
                                    return (
                                        <option key={i} value={x._id}>
                                            {x.username} ({x.firstName + ' ' + x.lastName})
                                        </option>
                                    );
                                })
                                : null
                        }
                    </Field>
                </Col>
                <Col md='4'>
                    <Button
                        color='warning'
                        size='sm'
                        onClick={() => getUsers(userList.find(e => e._id === selectedUser))}
                    >
                        <span className='btn-inner--text'>Select User</span>
                    </Button>
                </Col>
            </Row>
            {
                usersData?.length > 0 && usersData.map((x, i) => {
                    return (
                        <div className='d-flex align-items-center w-100' key={i}>
                            <p className='mb-0 mr-2'>{`${x.username}(${x.lastName} ${x.firstName})`}</p>
                            <Button
                                color='secondary'
                                type='button'
                                onClick={() => removeUser(i)}
                            >
                                <div className='btn-inner--text text-red'>
                                    <i 
                                        className='fa fa-trash'
                                        style={{fontSize: '1rem'}}
                                    />
                                    <span>Delete</span>
                                </div>
                            </Button>
                        </div>
                    );
                })
            }
        </>
    );
};
 
export default SelectedUsers;