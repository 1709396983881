import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import { withRouter } from 'react-router';

// nodejs library that concatenates classes
import classnames from 'classnames';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    // Modal,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

// import component 👇
// import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css';

import DynamicPriceCalc from './DynamicPriceCalc';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    dynamicPriceName: Yup.string()
        .required('Name is required'),
});

const DynamicPriceModal = (props) => {
    const [ dynamicPriceBetween, setDynamicPriceBetween ] = useState([]);
    const [ dynamicPriceFocused, setDynamicPriceFocused ] = useState(false);
    const [ dynamicPriceLastCondition, setDynamicPriceLastCondition ] = useState(0);
  
    const clone = (previousData) => {
        let cloneDynamicPriceBetween = cloneDeep(previousData);
        if(cloneDynamicPriceBetween.length > 0) {
            setDynamicPriceLastCondition(cloneDynamicPriceBetween[cloneDynamicPriceBetween.length - 1].type);
        }
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };
	
    const dynamicPriceBetweenOnChange = (val, name, index) => {
        let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);
        cloneDynamicPriceBetween[index][name] = val;
    
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const removeDynamicPriceBetween = (index) => {
        let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);
        cloneDynamicPriceBetween.splice(index, 1);
    
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const onSubmit = async(value) => {
        dynamicPriceBetween[dynamicPriceBetween.length - 1].type = dynamicPriceLastCondition;
        value.dynamicPriceBetween = dynamicPriceBetween;
        await client.authenticate()
            .then(()=>{
                return client.service('dynamicPrice').create(value);
            })
            .then((res) => {
                props.pushDynamicPrice(res);
                props.notificationOpen(true, 'success', 'Dynamic Price ' + res.dynamicPriceName + ' Added Successfully!');
                props.history.push(`/${props.role}/dynamic-price`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Formik
                initialValues={{
                    dynamicPriceName: '',
                    description: '',
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = propsChild;
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body pt-0'>

                                <h4>Dynamic Price Details</h4>
                                <Card className='shadow mb-4'>
                                    <CardBody className='bg-product m-4 rounded'>
                                        <div className='product-max-width'>
                                            <Row>
                                                <Col md='12'>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='dynamicPriceName'
                                                    >
                                                        Dynamic Price Name
                                                    </label>
                                                    <FormGroup className={classnames({
                                                        focused: dynamicPriceFocused
                                                    }, {
                                                        'has-danger': errors.dynamicPriceName && touched.dynamicPriceName
                                                    })}>
                                                        <InputGroup className='input-group-alternative mb-3'>
                                                            <InputGroupAddon addonType='prepend'>
                                                                <InputGroupText>
                                                                    <i className='ni ni-hat-3' />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input 
                                                                placeholder='Dynamic Price Name'
                                                                type='text'
                                                                id='dynamicPriceName'
                                                                value={values.dynamicPriceName}
                                                                onChange={handleChange}
                                                                onFocus={() =>
                                                                    setDynamicPriceFocused(true)
                                                                }
                                                                onBlur={e => {
                                                                    setDynamicPriceFocused(false);
                                                                    handleBlur(e);
                                                                }} 
                                                            />
                                                        </InputGroup>
                                                        {errors.dynamicPriceName && touched.mardynamicPriceNameginName && (
                                                            <div className='input-feedback'>{errors.dynamicPriceName}</div>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col md='12'>
                                                    <FormGroup>
                                                        <div className='mb-2'>
                                                            <small className='text-uppercase font-weight-bold'>
                                                                Description
                                                            </small>
                                                        </div>
                                                        <Input
                                                            className='form-control-alternative'
                                                            placeholder='Description'
                                                            id='description'
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            autoComplete='off'
                                                            type='textarea'
                                                            rows='3'
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>

                                <h2>
                                    Dynamic Price Rules
                                </h2>
              
                                <DynamicPriceCalc 
                                    dynamicPriceBetween={dynamicPriceBetween}
                                    dynamicPriceLastCondition={dynamicPriceLastCondition}
                                    setDynamicPriceBetween={setDynamicPriceBetween}
                                    dynamicPriceBetweenOnChange={dynamicPriceBetweenOnChange}
                                    removeDynamicPriceBetween={removeDynamicPriceBetween}
                                    dynamicPriceData={props.dynamicPriceData}
                                    setDynamicPriceLastCondition={setDynamicPriceLastCondition}
                                    clone={clone}
                                />
                            </div>
                            <div className='modal-footer'>
                                <Button
                                    color='secondary'
                                    data-dismiss='modal'
                                    type='button'
                                    onClick={() => {
                                        props.history.push(`/${props.role}/dynamic-price`);
                                    }}
                                >
                                    Discard
                                </Button>
                                <Button 
                                    color='primary' 
                                    type='submit'
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(DynamicPriceModal);