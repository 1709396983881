import React from 'react';
import {connect} from 'react-redux';

// reactstrap components
import {
    Container,
} from 'reactstrap';

const LiveChat = (props) => {
    return (  
        <>
            <div className='header' style={{height: 64}}>
                <span className='mask' />
            </div>
            <iframe 
                src={props.companyInfo.customerSupportUrl} 
                style={{
                    minHeight: 700
                }}
                width='100%'
            />
            <div className='pb-8'></div>
        </>
    );
};

const mapStateToProps = state => ({
    companyInfo: state.company.data,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);
