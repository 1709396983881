import React, { useState } from 'react';
import MarginsModal from '../components/Margins/MarginsModal';
import { connect } from 'react-redux';
import { fetchMargins, pushMargins, updateMargins, removeMargins } from '../redux/actions/marginsActions';
import getSymbolFromCurrency from 'currency-symbol-map';

import Notifications from '../components/Extra/Notifications.js';

const MarginsCreate = (props) => {
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);

    const notificationOpen = (bool, color, message) => {
        setIsOpen(bool);
        setColor(color);
        setMessage(message);
    };

    return (  
        <>
            <div className='header' style={{height: 64}}>
                <span className='mask' style={{backgroundColor: '#fff'}} />
            </div>
            <div className='mt-4'>
                <MarginsModal 
                    // modalVisible={modalVisible} 
                    // toggleModal={toggleModal}
                    notificationOpen={notificationOpen} 
                    pushMargins={props.pushMargins}
                    marginsData={props.marginsData}
                    currency={props.currency}
                    role={props.role}
                />
            </div>
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    marginsData: state.margins.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
});
  
const mapDispatchToProps = {
    fetchMargins: fetchMargins,
    pushMargins: pushMargins,
    updateMargins: updateMargins,
    removeMargins: removeMargins,
};
export default connect(mapStateToProps, mapDispatchToProps)(MarginsCreate);