/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchMargins, pushMargins, updateMargins, removeMargins } from '../redux/actions/marginsActions';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import ModalConfirmation from "../components/Extra/ModalConfirmation.js"

import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"
import axios from "axios"

const PAGESIZE = 20;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const Margins = (props) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false)
  const [ modalConfirm, setModalConfirm ] = useState(false)
  const [ dataEdit, setDataEdit ] = useState({})
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    fetchData(props.userId, props.fetchMargins, notificationOpen, 0, 1)
  }, [props.userId, props.fetchMargins])

  const toggleModal = () => {

    if(props.role !== 'superadmin'){
      // If not superadmin
      // Check numbOfMargins
      let numbOfMargins = props.userInfo.numbOfMargins?props.userInfo.numbOfMargins:3
      if(props.marginsData.length < numbOfMargins){
        setMmodalVisible(!modalVisible)
      }else{
        notificationOpen(true, 'danger', "Exceed Number of Algorithm!")
      }
    }else{
      setMmodalVisible(!modalVisible)
    }
  }

  const toggleModalEdit = () => {
    setMmodalVisibleEdit(!modalVisibleEdit)
  }

  const toggleModalConfirm = () => {
    setModalConfirm(!modalConfirm)
  }

  const modalCallback = (e) => {
    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}deleteMarginAndPatchProductsMargin`, {
        role: props.role,
        marginId: dataEdit._id
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      // console.log(res)
      props.removeMargins({
        _id: dataEdit._id
      })
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }
  
  const fetchData = (userId, fetchMargins, notificationOpen, skip, currentPage) => {
    if(userId){
      client.authenticate()
      .then(()=>{
        return client.service('margins').find({
          query: {
            userId: userId,
            $skip: skip,
            $limit: PAGESIZE,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        setPagination(paginationCount(res.total, currentPage))
        fetchMargins(res.data)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-center mb-0"
          listClassName="justify-content-center mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                fetchData(props.userId, props.fetchMargins, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i)); 
                    fetchData(props.userId, props.fetchMargins, notificationOpen, (i-1)*PAGESIZE, i)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                fetchData(props.userId, props.fetchMargins, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  return (  
    <>
      <div className="header" style={{height: 64}}>
        <span className="mask" style={{backgroundColor: '#fff'}} />
      </div>
      {
        props.role === 'superadmin'? (
          <Container fluid>
            <Card className="mt-4">
              <CardBody>
                <Row className="d-flex align-items-center">
                  <Col md="4" className="border-right">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="font-weight-bold">Currency</span>
                      <span class="badge badge-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                      </svg>
                      </span>
                    </div>
                    <h1 className="display-3 text-uppercase mb-0">{props.currency}</h1>
                  </Col>
                </Row>   
              </CardBody>
            </Card>
          </Container>
        ): (
          <Container fluid>
            <Card className="mt-4">
              <CardBody>
                <Row className="d-flex align-items-center">
                  <Col md="4" className="border-right">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="font-weight-bold">Currency</span>
                      <span class="badge badge-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                      </svg>
                      </span>
                    </div>
                    <h1 className="display-3 text-uppercase mb-0">{props.currency}</h1>
                  </Col>
                  <Col md="4" className="border-right">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="font-weight-bold">Max. Available Algorithm</span>
                      <span class="badge badge-primary">
                        <i 
                          style={{fontSize: 12}}
                          className="ni ni-tag" 
                        />
                      </span>
                    </div>
                    <h1 className="display-3">{props.userInfo.numbOfMargins?props.userInfo.numbOfMargins:3}</h1>
                  </Col>
                  <Col md="4">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="font-weight-bold">Utilised</span>
                      <span class="badge badge-primary">
                        <i 
                          style={{fontSize: 12}}
                          className="ni ni-cart" 
                        />
                      </span>
                    </div>
                    <h1 className="display-3">{props.marginsData.length}</h1>
                  </Col>
                </Row>   
              </CardBody>
            </Card>
          </Container>
        )
      }
      {/* Page content */}
      <Container fluid>
        {/* Dark table */}
        <Row className="pt-4 pb-3 d-flex align-items-center">
          <Col md="12" className="text-right">
            <Button
              color="primary"
              onClick={() => props.history.push(`/${props.role}/margins-create`)}
            >
              <span className="btn-inner--text">Create</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent border-0">
                <Media>
                  <Media body>
                    <h3 className="mb-0">Margin tables</h3>
                  </Media>
                </Media>
              </CardHeader>
              <div className="table-responsive mac-scrollbar">
                <Table className="align-items-center table-flush" style={{minHeight: 200}}>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Description</th>
                      <th scope="col" className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.marginsData.length > 0? (
                      props.marginsData.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              {v.marginName}
                            </td>
                            <td>{moment(v.createdAt).format("DD-MM-YYYY")}</td>
                            <td>{v.description}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    onClick={() => {
                                      props.history.push(`/${props.role}/margins-edit/${v._id}`)
                                      setDataEdit(v);
                                    }}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                      setDataEdit(v)
                                      toggleModalConfirm()
                                    }}
                                  >
                                      Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                          )
                        })
                      ): (
                        <tr>
                          <td>
                            <span style={{padding: '0px 10px'}}>No Record Found!</span>
                          </td>
                        </tr>
                        )
                      }
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationRender />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
     
      <ModalConfirmation 
        modalVisible={modalConfirm} 
        toggleModal={toggleModalConfirm} 
        modalCallback={modalCallback} 
        title="Delete Margin"
        content={(
          <div className="text-center">
            <div className="text-danger mb-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
            </div>
            <h3>Are you sure want delete this Margin?</h3>
            <hr style={{ marginTop: '1rem', marginBottom: '1rem', width: '80%'}}/>
            <p className="font-weight-400 text-muted"><strong>Product's Margin</strong> will be removed also those linked to this <strong>Algorithm</strong>!</p>
          </div>
        )}
      />
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 

const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
  marginsData: state.margins.data,
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
  fetchMargins: fetchMargins,
  pushMargins: pushMargins,
  updateMargins: updateMargins,
  removeMargins: removeMargins,
};
export default connect(mapStateToProps, mapDispatchToProps)(Margins);