import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ObjectId from 'bson-objectid';
import { connect } from 'react-redux';
import { loginDetails, rememberMe } from '../redux/actions/roleActions';
import { fetchCompany} from '../redux/actions/companyActions';
import { fetchStatus } from '../redux/actions/statusActions';
import { Formik } from 'formik';
import * as Yup from 'yup';

// nodejs library that concatenates classes
import classnames from 'classnames';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
} from 'reactstrap';

import Alerts from '../components/Extra/Alerts.js';

import client from '../feathers.js';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required')
});

const Login = (props) => {
    const [ emailFocused, setEmailFocused ] = useState(false);
    const [ passwordFocused, setPasswordFocused ] = useState(false);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('danger');
    
    const handleOpen = (bool) => {
        setIsOpen(bool);
    };

    const onSubmit = async(value) => {
        // eslint-disable-next-line no-undef
        localStorage.clear();
        client.authenticate({
            strategy: 'local', 
            email: value.email, 
            password: value.password
        })
            .then((res)=>{
                if(res.user.role === 'whitelabelsuperadmin' || res.user.role === 'superadmin' || res.user.role === 'admin'){
                    setIsOpen(true);
                    setColor('success');
                    setMessage('Huya! You\'re ready to go!');
                    props.rememberMe(value.email, value.rememberMe);
                    props.loginDetails(res);


                    if(res.user.role === 'whitelabelsuperadmin'){
                        setTimeout(() => props.history.push('/whitelabelsuperadmin/index'), 
                            500
                        );
                    }

                    if(res.user.role === 'superadmin'){
                        setTimeout(() => props.history.push('/superadmin/index'), 
                            500
                        );
                    }

                    if(res.user.role === 'admin'){
                        setTimeout(() => props.history.push('/admin/index'), 
                            500
                        );
                    }
        
                    if(res.user.role !== 'whitelabelsuperadmin'){
                        client.service('companies').find({
                            query: {
                                $or: [
                                    {
                                        userId: ObjectId(res.user.superAdminId)
                                    }, {
                                        userId: ObjectId(res.user._id)
                                    }
                                ],
                                $limit: 1,
                            }
                        })
                            .then((res)=>{
                                props.fetchCompany(res.data[0]);
                            });
                    }
                   
        
                }else{
                    setIsOpen(true);
                    setMessage('User role is not Admin!');
                }
            })
            .catch((err)=>{
                setIsOpen(true);
                setMessage(err.message);
            });
    };

    return (
        <>
            <Col lg='5' md='7'>
                <Alerts isOpen={isOpen} handleOpen={handleOpen} color={color} message={message} />
                <Card className='bg-secondary shadow border-0'>
                    <CardBody className='px-lg-5 py-lg-5'>
                        <div className='text-center text-muted mb-4'>
                            <small>Sign in with credentials</small>
                        </div>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                email: props.rmbEmail,
                                password: '',
                                rememberMe: props.rmbCbox
                            }}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <Form role='form' onSubmit={handleSubmit}>
                                        <FormGroup
                                            className={classnames('mb-3', {
                                                focused: emailFocused
                                            }, {
                                                'has-danger': errors.email && touched.email
                                            })}
                                        >
                                            <InputGroup className='input-group-alternative'>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        <i className='ni ni-email-83' />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder='Email'
                                                    type='email'
                                                    id='email'
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onFocus={e => 
                                                        setEmailFocused(true)
                                                    }
                                                    onBlur={e => {
                                                        setEmailFocused(false);
                                                        handleBlur(e);
                                                    }}
                                                />
                                            </InputGroup>
                                            {errors.email && touched.email && (
                                                <div className='input-feedback'>{errors.email}</div>
                                            )}
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                focused: passwordFocused
                                            }, {
                                                'has-danger': errors.password && touched.password
                                            })}
                                        >
                                            <InputGroup className='input-group-alternative'>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        <i className='ni ni-lock-circle-open' />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input       
                                                    placeholder='Password'
                                                    type='password'
                                                    id='password'
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    autoComplete='off'
                                                    onFocus={e => 
                                                        setPasswordFocused(true)
                                                    }
                                                    onBlur={e => {
                                                        setPasswordFocused(false);
                                                        handleBlur(e);
                                                    }}
                                                />
                                            </InputGroup>
                                            {errors.password && touched.password && (
                                                <div className='input-feedback'>{errors.password}</div>
                                            )}
                                        </FormGroup>
                                        <div className='custom-control custom-control-alternative custom-checkbox mt-4'>
                                            <input
                                                className='custom-control-input'
                                                type='checkbox'
                                                id='rememberMe'
                                                checked={values.rememberMe}
                                                value={values.rememberMe}
                                                onChange={handleChange}
                                            />
                                            <label
                                                className='custom-control-label'
                                                htmlFor='rememberMe'
                                            >
                                                <span>Remember me</span>
                                            </label>
                                        </div>
                                        <div className='text-center'>
                                            <Button
                                                className='my-4'
                                                color='primary'
                                                type='submit'
                                            >
                                                Sign in
                                            </Button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </CardBody>
                </Card>
                {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs={{size:"6", offset:"6"}}>
            <a
              className="text-light"
              href="/auth/register"
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row> */}
            </Col>
        </>
    );
};

const mapStateToProps = state => ({
    rmbEmail: state.role.rmbEmail,
    rmbCbox: state.role.rmbCbox
});

const mapDispatchToProps = {
    loginDetails: loginDetails,
    rememberMe: rememberMe,
    fetchCompany: fetchCompany,
    fetchStatus: fetchStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
