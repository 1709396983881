import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchLicenses, pushLicenses, updateLicenses } from '../redux/actions/licensesActions';
// import dayjs from 'dayjs';
import axios from 'axios';

// import moment from 'moment';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap';

import LicenseModal from '../components/Licenses/LicenseModal.js';
import LicenseModalEdit from '../components/Licenses/LicenseModalEdit.js';
import PasswordModal from '../components/Licenses/PasswordModal.js';
import StatusModal from '../components/Licenses/StatusModal.js';

import Notifications from '../components/Extra/Notifications.js';

import client from '../feathers';
import moment from 'moment';

const PAGESIZE = 10;

function paginationCount(length, currentPage) {
    return {
        total: length,
        per_page: PAGESIZE,
        current_page: currentPage,
        last_page: Math.ceil(length / PAGESIZE),
        from: ((currentPage - 1) * PAGESIZE) + 1,
        to: currentPage * PAGESIZE
    };
}

const Licenses = (props) => {
    const [ modalVisible, setMmodalVisible ] = useState(false);
    const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false);
    const [ modalVisiblePassword, setMmodalVisiblePassword ] = useState(false);
    const [ modalVisibleStatus, setModalVisibleStatus ] = useState(false);
    const [ totalActiveResellers, setTotalActiveResellers ] = useState(0);
    const [ activeLicense, setActiveLicense ] = useState(0);
    const [ totalLicense, setTotalLicense ] = useState(0);
    const [ dataEdit, setDataEdit ] = useState({});
    const [ pagination, setPagination ] = useState({});
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ currentDeactiveStatus, setCurrentDeactiveStatus] = useState(false);
  
    useEffect(() => {
        fetchData(props.userId, props.fetchLicenses, notificationOpen, 0, 1);
    }, [props.userId, props.fetchLicenses, props.deactive]);

    useEffect(() => {
        fetchStatictics(props.userId, props.fetchLicenses, notificationOpen, 0, 1);
    }, []);

    const toggleModal = () => {
        setMmodalVisible(!modalVisible);
    };

    const toggleModalEdit = () => {
        setMmodalVisibleEdit(!modalVisibleEdit);
    };

    const toggleModalPassword = () => {
        setMmodalVisiblePassword(!modalVisiblePassword);
    };

    const toggleModalStatus = () => {
        setModalVisibleStatus(!modalVisibleStatus);
    };

    const notificationOpen = (bool, color, message) => {
        setIsOpen(bool);
        setColor(color);
        setMessage(message);
    };

    const fetchStatictics = (userId, fetchLicenses, notificationOpen, skip, currentPage) => {
        if(userId){
            client.authenticate()
                .then(()=>{
                    return client.service('licenses').find({
                        query: {
                            deactive: false,
                        }
                    });
                })
                .then((res) => {
                    let totalActiveLicense = 0;
                    let totalAvaiableLicense = 0;
                    res.data.forEach((item) => {
                        totalActiveLicense += item.accUsed;
                        totalAvaiableLicense += item.numOfAccounts;
                    });
                    setActiveLicense(totalActiveLicense);
                    setTotalLicense(totalAvaiableLicense);
                    setTotalActiveResellers(res.total);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };
  
    const fetchData = (userId, fetchLicenses, notificationOpen, skip, currentPage) => {
        if(userId){
            client.authenticate()
                .then(()=>{
                    return client.service('licenses').find({
                        query: {
                            $skip: skip,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    setPagination(paginationCount(res.total, currentPage));
                    fetchLicenses(res.data);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

    const sendInvitationEmail = (userId) => {
        client.authenticate()
            .then((auth)=>{
                return axios.post(`${client.io.io.uri}sendInvitationEmail`,
                    {
                        userId
                    },
                    {
                        headers: {
                            'Authorization': auth.accessToken
                        }
                    });
            })
            .then((res) => {
                notificationOpen(true, 'success', 'Invitation sent!');
            })
            .catch((err)=>{
                notificationOpen(true, 'danger', 'Something went wrong. Failed to sent email!');
            });
    };

  
    const PaginationRender = () => {
        if(pagination.last_page > 0){
            let pageArr = [];
            for(let i = 1; i <= pagination.last_page; i++){
                pageArr.push(i);
            }
            return (
                <Pagination
                    className='pagination justify-content-center mb-0'
                    listClassName='justify-content-center mb-0'
                >
                    <PaginationItem className={(pagination.current_page > 1?'':'disabled')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                                fetchData(props.userId, props.fetchLicenses, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1);
                            }} 
                            tabIndex='-1'
                        >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {pageArr.map((i) => {
                        return (
                            <PaginationItem key={i} className={(pagination.current_page === i? 'active': '')}>
                                <PaginationLink
                                    onClick={() => {
                                        setPagination(paginationCount(pagination.total, i)); 
                                        fetchData(props.userId, props.fetchLicenses, notificationOpen, (i-1)*PAGESIZE, i);
                                    }} 
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem className={((pagination.current_page+1) > pagination.last_page?'disabled':'')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                                fetchData(props.userId, props.fetchLicenses, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1);
                            }} 
                        >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            );
        }else{
            return null;
        }
    };

    return (  
        <>
            <div className='header' style={{height: 64}}>
                <span className='mask' style={{backgroundColor: '#fff'}} />
                <Container className='align-items-center' fluid>
                </Container>
            </div>
            <Container fluid>
                <Card className='mt-4'>
                    <CardBody>
                        <Row className='d-flex align-items-center'>
                            <Col md='3' className='border-right'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className='font-weight-bold'>Number of active resellers</span>
                                </div>
                                <h1 className='display-3 text-uppercase mb-0'>{totalActiveResellers}</h1>
                            </Col>
                            <Col md='3' className='border-right'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className='font-weight-bold'>Number of active license</span>
                                </div>
                                <h1 className='display-3 text-uppercase mb-0'>{activeLicense}</h1>
                            </Col>
                            <Col md='3' className='border-right'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className='font-weight-bold'>Total available license</span>
                                </div>
                                <h1 className='display-3 text-uppercase mb-0'>{totalLicense}</h1>
                            </Col>
                        </Row>   
                    </CardBody>
                </Card>
            </Container>
            {/* Page content */}
            <Container fluid>
                {/* Dark table */}
                <Row className='pt-4 pb-3 d-flex align-items-center'>
                    <Col md='5'>
                    </Col>
                    <Col md='7' className='text-right'>
                        <Button
                            color='primary'
                            onClick={toggleModal}
                        >
                            <span className='btn-inner--text'>Create</span>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <div className='col'>
                        <Card className='shadow'>
                            <CardHeader className='bg-transparent border-0'>
                                <Media>
                                    <Media body>
                                        <h3 className='mb-0'>Licenses</h3>
                                    </Media>
                                </Media>
                            </CardHeader>
                            <div className='table-responsive mac-scrollbar'>
                                <Table className='align-items-center table-flush' style={{minHeight: 200}}>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Email</th>
                                            <th scope='col'>Created At</th>
                                            <th scope='col'>Status</th>
                                            <th scope='col'>License activated</th>
                                            <th scope='col'>Available License</th>
                                            <th scope='col'>Currency</th>
                                            <th scope='col'>Start date</th>
                                            <th scope='col'>End date</th>
                                            <th scope='col' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.licenses.length > 0? (
                                            props.licenses.map((v, i) => {   
                                                return (
                                                    <tr key={i}>
                                                       
                                                        <td>{v.email}</td>
                                                        {/* <td>{dayjs(v.createdAt).format('DD-MM-YYYY')}</td> */}
                                                        <td>{moment(v.createdAt).format('DD-MM-YYYY')}</td>
                                                        <td>{!v.deactive ? 'Active' : 'Deactive'}</td>
                                                        <td>{v.accUsed}</td>
                                                        <td>{v.numOfAccounts}</td>
                                                        <td>{v.currency?(v.currency).toUpperCase():v.currency}</td>
                                                        <td>{new Date(v.startDate).toISOString().split('T')[0]}</td>
                                                        <td>{new Date(v.endDate).toISOString().split('T')[0]}</td>
                                                        <td className='text-right'>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    className='btn-icon-only text-light'
                                                                    href='#pablo'
                                                                    role='button'
                                                                    size='sm'
                                                                    color=''
                                                                    onClick={(e) => e.preventDefault()}
                                                                >
                                                                    <i className='fas fa-ellipsis-v' />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='dropdown-menu-arrow' right>
                                                                    <DropdownItem
                                                                        onClick={() => {setDataEdit(v); toggleModalEdit();}}
                                                                    >
                                                                        Edit
                                                                    </DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={() => {setDataEdit(v); toggleModalPassword();}}
                                                                    >
                                                                        Edit Password
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem
                                                                        onClick={() => props.history.push('/superadmin/userProducts/'+v._id)}
                                                                    >
                                                                        Edit Products
                                                                    </DropdownItem> */}
                                                                    <DropdownItem
                                                                        onClick={() => {setDataEdit(v); toggleModalStatus(); setCurrentDeactiveStatus(v.deactive);}}
                                                                    >
                                                                        {!v.deactive ? 'Deactivate Whitelabel Acc' : 'Activate Whitelabel Acc'}
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem
                                                                        onClick={() => {sendInvitationEmail(v._id);}}
                                                                    >
                                                                        Email user account info
                                                                    </DropdownItem> */}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ): (
                                            <tr>
                                                <td>
                                                    <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                </td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            <CardFooter className='py-4'>
                                <nav aria-label='...'>
                                    <PaginationRender />
                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
            <LicenseModal 
                modalVisible={modalVisible} 
                toggleModal={toggleModal}
                notificationOpen={notificationOpen} 
                pushLicenses={props.pushLicenses}
            />
            <LicenseModalEdit 
                dataEdit={dataEdit}
                modalVisible={modalVisibleEdit} 
                toggleModal={toggleModalEdit}
                notificationOpen={notificationOpen} 
                updateLicenses={props.updateLicenses}
            />
            <PasswordModal 
                dataEdit={dataEdit}
                modalVisible={modalVisiblePassword} 
                toggleModal={toggleModalPassword}
                notificationOpen={notificationOpen} 
                updateLicenses={props.updateLicenses}
            />
            <StatusModal 
                dataEdit={dataEdit}
                modalVisible={modalVisibleStatus} 
                toggleModal={toggleModalStatus}
                notificationOpen={notificationOpen} 
                updateLicenses={props.updateLicenses}
                status={currentDeactiveStatus}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    usersData: state.users.data,
    licenses: state.licenses.data,
});

const mapDispatchToProps = {
    fetchLicenses: fetchLicenses,
    pushLicenses: pushLicenses,
    updateLicenses: updateLicenses,
};
export default connect(mapStateToProps, mapDispatchToProps)(Licenses);