import React from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    Row,
    Col,
    Input
} from 'reactstrap';
  
const ProductAssignDynamicPrice = (props) => {
    return (
        <Card className='shadow mb-4'>
            <CardBody>
                <small className=' font-weight-bold'>
                    Dynamic Price
                </small>
                <Row>
                    <Col md='12' className='text-center'>
                        <Input 
                            className='mr-2 w-auto d-inline-block'
                            value={props.dynamicPriceData.find(e => e._id === props.dynamicPriceName) ? props.dynamicPriceData.find(e => e._id === props.dynamicPriceName).dynamicPriceName: ''} 
                            disabled
                        />
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle caret color='secondary' className=''>
                                Assign Dynamic Price
                            </DropdownToggle>
                            <DropdownMenu className='w-100'>
                                <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                    <DropdownItem 
                                        className='bg-danger text-white text-capitalize' 
                                        value={''} 
                                        onClick={() => {
                                            props.setDynamicPriceName('');
                                        }}
                                    >
                                        Unlink Dynamic Price
                                    </DropdownItem>
                                    {
                                        props.dynamicPriceData.map((x, i) => {
                                            return (
                                                <DropdownItem
                                                    key={i} 
                                                    className='text-capitalize' 
                                                    value={x.dynamicPriceName} 
                                                    onClick={() => {
                                                        props.setDynamicPriceName(x._id);
                                                    }}
                                                >
                                                    {x.dynamicPriceName}
                                                </DropdownItem>
                                            );
                                        })
                                    }
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <br/>
                        <br/>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ProductAssignDynamicPrice;