/*eslint-disable*/
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
// nodejs library that concatenates classes
import classnames from 'classnames';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    // Modal,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

// import ProductTable from "./Products.js"
import SelectedProducts from './SelectedProducts.js';
import SelectedUsers from './SelectedUsers.js';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    groupName: Yup.string()
        .required('Group Name is required'),
});

const GroupsModal = (props) => {
    const [ groupFocused, setGroupFocused ] = useState(false);
    const [ productsData, setProductsData ] = useState([]);
    const [ displayProductsData, setDisplayProductsData ] = useState([]);
    const [ usersData, setUsersData ] = useState([]);

    const onSubmit = async(value) => {
    
        value.userIds = usersData.map(x => x._id);
        value.productIds = productsData.map(x => {
            return {
                productId: x._id,
                marginId: !isEmpty(x.marginSuperName)?x.marginSuperName._id: ''
            };
        });
    
        client.authenticate()
            .then(()=>{
                return client.service('product-groups').create(value);
            })
            .then((res) => {
                props.pushGroups(res);
                props.notificationOpen(true, 'success', 'Group ' + res.groupName + ' Added Successfully!');
                props.history.push(`/${props.role}/groups-management`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const getProducts = (data) => {
        let result = [];
        data.map(x => {
            const found = productsData.some(el => el._id === x._id);
            if (!found) result.push(x);
            return x;
        });
        setProductsData(productsData.concat(result));
    };

    const getUsers = (data) => {
        if(!isEmpty(data)){
            let findIfExist = usersData.findIndex(x => x._id === data._id);
            if(findIfExist === -1){
                setUsersData(usersData.concat(data));
            }
        }
    };

    return (  
        <>
            <Formik
                initialValues={{
                    groupName: '',
                    description: '',
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = propsChild;
                    return (
                        <Form role="form" onSubmit={handleSubmit}>
                            <div className="modal-body pt-0">

                                <h4>Group Details</h4>
                                <Card className="shadow mb-4">
                                    <CardBody className="bg-product m-4 rounded">
                                        <div className="product-max-width">
                                            <Row>
                                                <Col md="12">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="groupName"
                                                    >
                          Group Name
                                                    </label>
                                                    <FormGroup className={classnames({
                                                        focused: groupFocused
                                                    }, {
                                                        'has-danger': errors.groupName && touched.groupName
                                                    })}>
                                                        <InputGroup className="input-group-alternative mb-3">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="ni ni-hat-3" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input 
                                                                placeholder="Group Name"
                                                                type="text"
                                                                id="groupName"
                                                                value={values.groupName}
                                                                onChange={handleChange}
                                                                onFocus={e =>
                                                                    setGroupFocused(true)
                                                                }
                                                                onBlur={e => {
                                                                    setGroupFocused(false);
                                                                    handleBlur(e);
                                                                }} 
                                                            />
                                                        </InputGroup>
                                                        {errors.groupName && touched.groupName && (
                                                            <div className="input-feedback">{errors.groupName}</div>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <div className="mb-2">
                                                            <small className="text-uppercase font-weight-bold">
                              Description
                                                            </small>
                                                        </div>
                                                        <Input
                                                            className="form-control-alternative"
                                                            placeholder="Description"
                                                            id="description"
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            autoComplete="off"
                                                            type="textarea"
                                                            rows="3"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card> 

                                <div className="mb-4">
                                    <SelectedUsers 
                                        getUsers={getUsers} 
                                        usersData={usersData}
                                        setUsersData={setUsersData}
                                    />
                                </div>

                                {/* <div className="mb-4">
                                    <ProductTable 
                                    getProducts={getProducts} 
                                    notificationOpen={props.notificationOpen}
                                    />
                                </div> */}                    
                                {/* <Input
                                    className='mb-2'
                                    style={{width: 300}}
                                    onChange={(e) => {
                                        e.target.value?
                                        setDisplayProductsData(
                                            displayProductsData.filter((i) => i.name
                                            .toLowerCase()
                                            .includes(e.target.value.toLowerCase())),
                                        )
                                        : setDisplayProductsData(productsData)
                                    }}
                                    placeholder='Search for product...'
                                /> */}

                                <SelectedProducts 
                                    productsData={productsData}
                                    displayProductsData={displayProductsData}
                                    setProductsData={setProductsData}
                                    setDisplayProductsData={setDisplayProductsData}
                                    tableSorting={() => {}}
                                    sortList={() => {}}
                                    notificationOpen={props.notificationOpen}
                                    marginsData={props.marginsData}
                                />

                            </div>

                            <div className="modal-footer">
                                <Button
                                    color="secondary"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                        props.history.push(`/${props.role}/groups-management`);
                                    }}
                                >
                  Discard
                                </Button>
                                <Button 
                                    color="primary" 
                                    type="submit"
                                >
                  Confirm
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(GroupsModal);