import React, { useState } from 'react';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
    Card,
    CardBody,
    Form,
    Modal,
    Row,
    Col,
} from 'reactstrap';

import client from '../../feathers.js';

const AssignProductGroups = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ groupName, setGroupName ] = useState('');
    
    const onSubmit = async(value) => {
        if(props.checkboxChecked.length > 0)
            setLoading(true);
        let getProductGroup = props.groupList.find(e => e._id === groupName);

        let getProduct = [];
        props.checkboxChecked.map(x => {
            props.productsData.map(x1 => {
                if(x === x1._id){
                    getProduct.push(x1);
                }
                return x1;
            });
            return x;
        });

        let result = [];
        getProduct.map(x => {
            const found = getProductGroup.productIds.some(el => el._id === x._id);
            if (!found) result.push(x);
            return x;
        });

        let filterExistProduct = getProductGroup.productIds.concat(result);

        let productIds = filterExistProduct.map(x => {
            return {
                productId: x._id,
                marginId: !isEmpty(x.marginSuperName)?x.marginSuperName._id: ''
            };
        });
        
        client.authenticate()
            .then(()=>{
                return client.service('product-groups').patch(getProductGroup._id, {
                    productIds,
                });
            })
            .then((res) => {
                props.notificationOpen(true, 'success', 'Group ' + res.groupName + ' Edit Successfully!');
                props.toggleModal();
                setLoading(false);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
                setLoading(false);
            });
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered status-modal'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Assign Products Group
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <Card className='shadow mb-4'>
                                        <CardBody>
                                            {/* <small className="text-uppercase font-weight-bold">
                        Profit Margin
                                            </small> */}
                                            <Row>
                                                <Col md='12' className='text-center'>
                                                    <Input 
                                                        className='mr-2 w-auto d-inline-block'
                                                        value={props.groupList.find(e => e._id === groupName)? props.groupList.find(e => e._id === groupName).groupName: ''} 
                                                        disabled
                                                    />
                                                    <UncontrolledDropdown nav inNavbar>
                                                        <DropdownToggle caret color='secondary' className='text-uppercase'>
                                                            Apply Group
                                                        </DropdownToggle>
                                                        <DropdownMenu className='w-100'>
                                                            <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                                                {
                                                                    props.groupList.map((x, i) => {
                                                                        return (
                                                                            <DropdownItem
                                                                                key={i} 
                                                                                className='text-capitalize' 
                                                                                value={x.groupName} 
                                                                                onClick={() => {
                                                                                    setGroupName(x._id);
                                                                                }}
                                                                            >
                                                                                {x.groupName}
                                                                            </DropdownItem>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    {/* <br/>
                                                    <br/>
                                                    <p>*To edit margin, Please go to margin page to edit and apply again</p> */}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                        disabled={loading}
                                    >
                                        {loading? (
                                            <div className='spinner-border mr-1' role='status' style={{height: '1rem', width: '1rem'}}>
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        ):null}
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default AssignProductGroups;