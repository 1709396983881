/*eslint-disable*/
import React, { useRef, useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep"
import { Formik } from 'formik';
import * as Yup from 'yup';
import isEmpty from "lodash/isEmpty"
import { withRouter } from 'react-router'

// import ArtworkOption from "./ArtworkOption"
import ArtworkServices from "./ArtworkServices"
import AdditionalCalc from "./AdditionalCalc"
import AdditionalOptions from "./AdditionalOptions"
import CSVAdditionalOptions from "./CSVAdditionalOptions"

// import ProductDesc from "./ProductDesc"
import UploadFile from "./UploadFile"
import CSVForm from "./CSVForm"
import StickerForm from "./StickerForm"
import PosterForm from "./PosterForm"
import DocumentForm from "./DocumentForm"
import BookletForm from './Booklet/BookletForm';
import LooseSheetForm from './LooseSheet/LooseSheetForm';
import CustomQuantity from './Common/CustomQuantity';

import ProductAssignDynamicPrice from '../DynamicPrice/ProductAssignDynamicPrice';
import { checkDynamicPriceEnabled } from '../../utils/dynamicPrice';

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  // Modal,
  Row,
  Col
} from "reactstrap";

// import component 👇
// import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

import client from "../../feathers.js"
import { storage } from "../../firebase";
import axios from "axios"
import env from "../../env.js"
import apiType from "./apiProductType.js"
import { useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Product Name is required'),
  source: Yup.string()
    .required('Source is required'),
})

function capitalize(s){
  let text = s?.toLowerCase();
  return text && text[0].toUpperCase() + text.slice(1);
}

const ProductModalEdit = (props) => {
  const { id } = useParams()
  const bgFileEl = useRef()
  const uploadFileEl = useRef()
  // const [ availability, setAvailability ] = useState('In Stock')
  const [ fileArray, setFileArray ] = useState([])
  const [ csvFile, setCsvFile ] = useState({})
  const [ timelineArray, setTimelineArray ] = useState([])
  const [ marginName, setMarginName ] = useState('')
  const [ dynamicPriceName, setDynamicPriceName ] = useState('');
  const [ nameFocused, setNameFocused ] = useState(false)
  // const [ codeFocused, setCodeFocused ] = useState(false)
  const [ amountFocused, setAmountFocused ] = useState(false)
  const [ progress, setProgress ] = useState(0)
  const [ loading, setLoading ] = useState(false)
  const [ items, setItems ] = useState([])
  const [ additionOption, setAdditionOption ] = useState([])
  const [ manualCostBetween, setManualCostBetween ] = useState([])
  const [ manualCostGreater, setManualCostGreater ] = useState({minQ: 0, amount: 0})
  const [ manualCostDefault, setManualCostDefault ] = useState({minQ: 0, amount: 0})
  const [ renameCheck, setRenameCheck ] = useState(true)
  const [ productGroups, setProductGroups ] = useState([])
  const [ source, setSource ] = useState('');

  const [ dataEdit, setDataEdit ] = useState({})

  useEffect(() => {
    
    client.authenticate()
    .then(()=>{
      return client.service('products').get(id)
    })
    .then((res)=>{
      setDataEdit(res)
    })
    .then((res)=>{
      return client.service('product-groups').find({
        query: {
          userIds: {
            $in: props.userId
          }
        }
      })
    })
    .then((res)=>{
      setProductGroups(res.data)
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }, [id])

  useEffect(() => {
    console.log(dynamicPriceName);
  }, [dynamicPriceName]);

  useEffect(() => {
    if(!isEmpty(dataEdit)){

      if(dataEdit.marginName){
        setMarginName(dataEdit.marginName)
      }
      if(props.role === 'superadmin') {
        if(dataEdit.superDynamicPriceId){
          setDynamicPriceName(dataEdit.superDynamicPriceId)
        }
      } else {
        if(dataEdit.dynamicPriceName){
          setDynamicPriceName(dataEdit.dynamicPriceName)
        }
      }
      if(dataEdit.source){
        setSource(dataEdit.source)
      }
      setFileArray(dataEdit.productImg)
      setCsvFile(dataEdit.csvFile)
      setTimelineArray(dataEdit.timelines)
      // new function

      // setAvailability(dataEdit.availability)
      setItems(dataEdit.items?dataEdit.items:[])
      setAdditionOption(dataEdit.additionOption?dataEdit.additionOption:[])
      setManualCostBetween(dataEdit.manualCostBetween?dataEdit.manualCostBetween:[])
      if(!isEmpty(dataEdit.manualCostGreater)){
        setManualCostGreater(dataEdit.manualCostGreater)
      }
      if(!isEmpty(dataEdit.manualCostDefault)){
        setManualCostDefault(dataEdit.manualCostDefault)
      }
    }
  }, [dataEdit])

  const handleFileChange = async(e) => {
    setProgress(0)

    let singleFile = e.target.files[0]
    let fileType = (singleFile.type === "application/vnd.ms-excel" || singleFile.type === "text/csv")

    if(fileType){
      let newFileName = (Math.random().toString(36).substring(2, 15) + "-" + 
        singleFile.lastModified + "-" + 
        singleFile.name)
      let formData = new FormData()
      formData.append('file', singleFile, newFileName)

      let modified = formData.get('file')

      setCsvFile(modified)

      return e.target.value = null
    }else{
      window.alert("Only Accept CSV File!")
      return false
    }
  }

  const handleImgChange = (e) => {
    setProgress(0)

    let singleFile = e.target.files[0]
    // let fileType = (singleFile.type === "image/jpeg" || singleFile.type === "image/jpg" || singleFile.type === "image/png" || singleFile.type === "image/gif")

    // if(singleFile.size <= 2000000 && fileType){
      let newFileName = (Math.random().toString(36).substring(2, 15) + "-" + 
        singleFile.lastModified + "-" + 
        singleFile.name)
      let formData = new FormData()
      formData.append('file', singleFile, newFileName)

      let modified = formData.get('file')

      let objectImg = URL.createObjectURL(e.target.files[0])
      let imgData = {
        name: singleFile.name,
        file: modified,
        preview: objectImg
      }
      setFileArray(fileArray.concat(imgData))

      return e.target.value = null
    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
  }

  // const removeFileInedx = (index) => {
  //   URL.revokeObjectURL(fileArray[index].preview)
  //   if(fileArray[index].fileName){
  //     handleDelete('productImages', fileArray[index].fileName)
  //   }
  //   let result = cloneDeep(fileArray)
  //   result.splice(index, 1)
  //   setFileArray(result)
  // }

  // const handleDelete = (path, currentFileName) => {
  //   // Create a reference to the file to delete
  //   var desertRef = storage.ref(`${path}`).child(currentFileName);

  //   // Delete the file
  //   desertRef.delete().then(function() {
  //     // File deleted successfully
  //     console.log('File deleted successfully')
  //   }).catch(function(error) {
  //     // Uh-oh, an error occurred!
  //     console.log('Uh-oh, an error occurred!')
  //   });
  // }

  const removeFile = () => {
    fileArray.map((v) => {
      return URL.revokeObjectURL(v.preview)
    })
    setFileArray([])
  }

  const handleUpload = (path, file) => {
    setProgress(0)
    return new Promise(resolve => {
      let uploadTask = storage.ref(`${path}/${file.name}`).put(file)
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        error => {
          console.log(error);
        },
        ()=> {
          storage
            .ref(`${path}`)
            .child(file.name)
            .getDownloadURL()
            .then(url => {
              return resolve(url)
            })
        }
      )
    })
  }

  const imageProcess = (path, file) => {
    let result = handleUpload(path, file).then(v => v)
    return result
  }

  const csvUpload = async(file) => {

    const formData = new FormData();
    formData.append('NAME', 'Fred');
    formData.append('role', props.role);
    formData.append('file', file)

    // if(env.role === "main" && props.role === "superadmin"){
    //   await axios({
    //     method: 'post',
    //     url: `https://pricecal-api.expressprint.com.my/uploadCSVLocal`,
    //     data: formData,
    //     config: { headers: {'Content-Type': 'multipart/form-data' }}
    //   })
    //   .then((res) => {
    //     // return (res.data)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
    // }

    return await axios({
      method: 'post',
      url: `${client.io.io.uri}uploadCSVLocal`,
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then((res) => {
      return (res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleDeleteCSV = (fileName) => {
    // if(env.role === "main" && props.role === "superadmin"){
    //   axios({
    //     method: 'post',
    //     url: `https://pricecal-api.expressprint.com.my/deleteCSVLocal`,
    //     data: {fileName, role: props.role},
    //     config: { headers: {'Content-Type': 'application/json' }}
    //   })
    //   .then((res) => {
    //     return (res.data)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
    // }
    
    axios({
      method: 'post',
      url: `${client.io.io.uri}deleteCSVLocal`,
      data: {fileName, role: props.role},
      config: { headers: {'Content-Type': 'application/json' }}
    })
    .then((res) => {
      return (res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const onSubmit = async(value) => {
    setLoading(true)

    if(dataEdit.name === value.name){
      approvedUpdate()
    }else{
      let checkExists = await client.authenticate()
      .then(()=>{
        return client.service('products').find({
          query: {
            name: value.name,
            userId: props.userId,
            $limit: 1,
          }
        })
      })
      if(checkExists.data.length === 0){
        approvedUpdate()
      }else{
        setLoading(false)
        props.notificationOpen(true, 'danger', "Product " + value.name + " Is Exists!")
      }
    }

    async  function approvedUpdate(){

      // sort preSize Others
      if(value.preSize?.length > 0){
        let findOthers = value.preSize?.findIndex(e => e.title === 'Others')
        if(findOthers !== -1){
          let getOthers = value.preSize?.filter(e => e.title === 'Others')
          value.preSize.splice(findOthers, 1)
          value.preSize = value.preSize.concat(getOthers)
        }
      }

      // value.availability = availability
      value.timelines = timelineArray
      // new function
      value.marginName = marginName

      if(props.role === 'superadmin') {
        value.superDynamicPriceId = dynamicPriceName
      } else {
        value.dynamicPriceName = dynamicPriceName
      }

      value.items = items
      value.additionOption = additionOption
      value.manualCostBetween = manualCostBetween
      value.manualCostGreater = manualCostGreater
      value.manualCostDefault = manualCostDefault

      value.customQuantity.quantities = [...new Set(value.customQuantity.quantities)];
      value.customQuantity.quantities = value.customQuantity.quantities.filter((x) => x !== 0);
      value.customQuantity.quantities.sort((i, x) => parseInt(i) - parseInt(x));
      
      if(fileArray.length > 0){
        let cloneFileArr = cloneDeep(fileArray)
        let processImg = await cloneFileArr.map(async(v) => {
          if(v.file){
            if(v.file.name){
              let imgUrl = await imageProcess('productImages', v.file)
              v.fileUrl = imgUrl
              v.fileName = v.file.name
              delete v.file
              delete v.preview
              delete v.name
              return v
            }else{
              return v
            }
          }else{
            return v
          }
        })
        
        const result = await Promise.all(processImg)
        value.productImg = result
      }
      
      if(csvFile.name){
        let productDataUrl = await csvUpload(csvFile)

        // if removed, fileName will be moved as well
        if(dataEdit.csvFile.fileName){
          handleDeleteCSV(dataEdit.csvFile.fileName)
          updateAllProductsImage(dataEdit._id, dataEdit.csvFile.fileName, csvFile.name, productDataUrl, props.updateProducts)
        }
        
        value.csvFile = {}
        value.csvFile.fileUrl = productDataUrl
        value.csvFile.fileName = csvFile.name
      }
      
      // if state nothing => remove all file
      // get from record dataEdit.csvFile
      if(!csvFile.name && !csvFile.fileName){
        if(dataEdit.csvFile.fileName){
          handleDeleteCSV(dataEdit.csvFile.fileName)
        }
        value.csvFile = {}
      }

      // if(renameCheck){
      //   if(props.role === 'superadmin'){
      //     if(value.name !== dataEdit.name){
      //       // patch all
      //       // patch if all name
      //       client.authenticate()
      //       .then((auth)=>{
      //         return axios.post(`${client.io.io.uri}productRename`, {
      //           oldName: dataEdit.name,
      //           newName: value.name,
      //           userId: props.userId
      //         },
      //         {
      //           headers: {
      //             'Authorization': auth.accessToken
      //           }
      //         })
      //       })
      //       .then((res) => {
      //         // console.log(res)
      //       })
      //       .catch((err)=>{
      //         if(err.name === "NotAuthenticated"){
      //           props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      //         }else{
      //           props.notificationOpen(true, 'danger', err.message)
      //         }
      //       })
      //     }
      //   }
      // }

      client.authenticate()
      .then(()=>{
        return client.service('products').patch(dataEdit._id, value)
      })
      .then((res) => {
        res.marginName = props.marginsData.find(e => e._id === marginName)
        res.dynamicPriceName = props.dynamicPriceData.find(e => e._id === dynamicPriceName)

        // if not superadmin => patch 1
        if(props.role === "superadmin"){
          if( 
            dataEdit.source === 'sticker'
            || dataEdit.source === 'poster'
            || dataEdit.source === 'loose-sheet'  
            || dataEdit.source === 'certificate'
            || dataEdit.source === 'document'
            || dataEdit.source === 'api'
            || dataEdit.source === 'csv'
            || dataEdit.source === 'booklet'
          ){
            updateAllProduct(dataEdit.name, value.name, res)
          }else{
            updateAllProductLight(dataEdit.name, value.name, res)
          }
        }else{
          props.notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
        }

        props.updateProducts(res)
        removeFile()
        setCsvFile({})
        setLoading(false)
        props.history.push(`/${props.role}/`)
      })
      .catch((err)=>{
        removeFile()
        setLoading(false)
        if(err.name === "NotAuthenticated"){
          props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          props.notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  const updateAllProductsImage = (id, oldFileName, fileName, productDataUrl, updateProducts) => {
    client.authenticate()
    .then((auth)=>{

      // if(env.role === "main" && props.role === "superadmin"){
      //   axios.post(`https://pricecal-api.expressprint.com.my/updateAllProductFileName`, {
      //     _id: id,
      //     oldFileName: oldFileName,
      //     fileUrl: productDataUrl,
      //     fileName: fileName
      //   })
      // }
      
      return axios.post(`${client.io.io.uri}updateAllProductFileName`, {
        _id: id,
        oldFileName: oldFileName,
        fileUrl: productDataUrl,
        fileName: fileName
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      if(props.productsData.length > 0){
        let sameFileProducts = props.productsData.filter(e => e.csvFile.fileName === oldFileName)
        sameFileProducts.map(x => {
          x.csvFile = {
            fileUrl: productDataUrl,
            fileName: fileName
          } 
          props.updateProducts(x)
        })
      }
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }

  // props
  const updateAllProduct = (oldName, newName, newData) => {
    let cloneData = cloneDeep(newData)
    delete cloneData._id
    delete cloneData.__v
    delete cloneData.updatedAt
    delete cloneData.updatedBy
    delete cloneData.userId
    delete cloneData.createdAt
    delete cloneData.createdBy
    delete cloneData.productImg
    delete cloneData.timelines
    delete cloneData.artworkLink
    delete cloneData.artworkServiceAmount
    delete cloneData.artworkoption
    delete cloneData.artworkServices
    delete cloneData.availability
    delete cloneData.category
    delete cloneData.code
    delete cloneData.dateToFirst
    delete cloneData.description
    delete cloneData.keywords
    delete cloneData.mostPopular
    delete cloneData.shippingInfo
    delete cloneData.packageSize
    delete cloneData.productDetail
    delete cloneData.marginName
    delete cloneData.dynamicPriceName
    delete cloneData.marginSuperName
    delete cloneData.source
    
    if(cloneData.manualCostBetween){
      cloneData.manualCostBetween.map(x => {
        delete x._id
        return x
      })
    }
    if(cloneData.additionOption){
      if(cloneData.additionOption.length > 0){
        cloneData.additionOption.map(x => {
          delete x._id
          x.option.map(x1 => {
            delete x1._id
            return x1
          })
          return x
        })
      }
    }

    if(cloneData.items){
      if(cloneData.items.length > 0){
        cloneData.items.map(x => {
          delete x._id
          x.amountBetween.map(x1 => {
            delete x1._id
            return x1
          })
          x.variations.map(x1 => {
            delete x1._id
            return x1
          })
          return x
        })
      }
    }

    if(isEmpty(cloneData.csvFile)){
      cloneData.csvFile = {
        fileName: '',
        fileUrl: '',  
      }
    }

    cloneData.name = oldName

    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}updateAllProduct`,
      {
        data: cloneData,
        newName,
        userId: props.userId
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      props.notificationOpen(true, 'success', "All Product " + cloneData.name + " Update Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const updateAllProductLight = (oldName, newName, newData) => {
    let data = {
      name: oldName,
      supplier: newData.supplier
    }
   
    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}updateAllProduct`,
      {
        data: data,
        newName,
        userId: props.userId
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      props.notificationOpen(true, 'success', "All Product " + newName + " Update Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }
  return (  
    <>
      {/* <Modal
        className="modal-dialog-centered product-modal"
        isOpen={props.modalVisible}
        toggle={() => {props.toggleModal(); removeFile()}}
      > */}
      {/* <Drawer
        open={props.modalVisible}
        onClose={props.toggleModal}
        className='shadow-lg overflow-auto mac-scrollbar'
        direction='right'
        enableOverlay={false}
        size="81%"
      > */}
        {/* <div className="px-4 py-2 d-flex align-items-center">
          <button
            aria-label="Close"
            className="close float-none"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true} style={{fontSize: '2rem', height: '2.25rem', width: '2.25rem', lineHeight: '2.8rem'}}>
              <i className="ni ni-fat-remove"/>
            </span>
          </button>
          <h4 className="modal-title">
            Edit Product
          </h4>
        </div> */}
        {/* <hr className="mt-0 mb-2"/> */}
        <Formik
          enableReinitialize
          initialValues={{
            name: dataEdit?.name,
            source: dataEdit?.source,
            apiType: dataEdit?.apiType,
            groupType: dataEdit?.groupType,
            sourceFilterBy: dataEdit?.sourceFilterBy,
            supplier: dataEdit?.supplier,
            csvFile: dataEdit?.csvFile,
            bookletData: dataEdit?.bookletData,
            looseSheetData: dataEdit?.looseSheetData,
            assignProduct: dataEdit?.assignProduct,
            customQuantity: dataEdit?.customQuantity ?? {
              isAdminSet: false,
              enabled: false,
              type: 'minMax',
              min: 1,
              max: 0,
              incrementStart: 1,
              incrementBy: 1,
              incrementMax: 1,
              quantities: [1],
            },
            preSize: dataEdit?.preSize?dataEdit?.preSize:[],
            roundedOptions: dataEdit?.roundedOptions,
            punchHoleOptions: dataEdit?.punchHoleOptions,
            hotstampOptions: dataEdit?.hotstampOptions?dataEdit.hotstampOptions:[],
            splitOptions: dataEdit?.splitOptions?dataEdit.splitOptions:[],
            additionalData: dataEdit?.additionalData?dataEdit.additionalData:[],
            code: dataEdit?.code,
            category: dataEdit?.category,
            netAmount: dataEdit?.netAmount,
            foldingBase: dataEdit?.foldingBase,
            foldingPercent: dataEdit?.foldingPercent,
            calcSqFtIn: dataEdit?.calcSqFtIn,
            doubleSidePercent: dataEdit?.doubleSidePercent,
            mergingFee: dataEdit?.mergingFee,
            merginVariable: dataEdit?.merginVariable,
            status: dataEdit?.status,
            mostPopular: dataEdit?.mostPopular,
            artworkServices: dataEdit?.artworkServices?dataEdit.artworkServices:[],
            widthRange: dataEdit?.widthRange? dataEdit.widthRange: {
              min: 0,
              max: 0,
            },
            heightRange: dataEdit?.heightRange? dataEdit.heightRange: {
              min: 0,
              max: 0,
            },
            bleeding: dataEdit?.bleeding? dataEdit.bleeding: {
              width: 0,
              height: 0,
            },

            // artworkServiceAmount: dataEdit.artworkServiceAmount,
            // artworkoption: dataEdit.artworkoption,
            // artworkLink: dataEdit.artworkLink,
            packageSize: dataEdit?.packageSize,
            keywords: dataEdit?.keywords?dataEdit.keywords:[],
            // localShippingAmount: dataEdit.localShippingAmount,
            // sgDelivery: !isEmpty(dataEdit.sgDelivery)?dataEdit.sgDelivery: { 
            //   name: '', minAmt: 0, amount: 0 
            // },
            availability: dataEdit?.availability,
            description: dataEdit?.description,
            shippingInfo: dataEdit?.shippingInfo,
            manualPercent: dataEdit?.manualPercent?dataEdit.manualPercent:0
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {propsChild => {
            const {
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            } = propsChild;
            return (
              <Form role="form" onSubmit={handleSubmit}>
                <div className="modal-body pt-0">
                  {/* <div className="mb-2">
                    <small className=" font-weight-bold">
                      Product Images
                    </small>
                  </div> */}
                  {/* <Row className="justify-content-center mb-4">
                    {fileArray.length > 0? 
                      fileArray.map((v, i) => {
                        return (
                          <Col md="4" lg="4" key={i}>
                            <FormGroup className="text-center">
                              <Card className="mb-2 shadow-sm">
                                <CardBody className="p-0">
                                  <div className="file-component">
                                    <div className="file-overlay">
                                      <div className="file-buttons">
                                        <Button
                                          className="border-0"
                                          color="danger"
                                          onClick={() => removeFileInedx(i)}
                                          >
                                          <div className="btn-inner--icon">
                                            <span className="btn-inner--text">Remove</span>
                                          </div>
                                        </Button>
                                      </div>
                                    </div>
                                    <img className="w-100" src={v.preview} alt="entreship sol" />
                                  </div>
                                </CardBody>
                              </Card>
                              <span style={{ overflowWrap: 'break-word' }}>{v.name}</span>
                            </FormGroup>
                          </Col>
                        )
                      }): null}
                      {fileArray.length < 10? (
                      <>
                        <div className="file-upload">
                          <input 
                            type="file" 
                            ref={bgFileEl} 
                            onChange={(e) => handleImgChange(e)} 
                            hidden />                
                            <div className="progessBar" style={{ width: progress }}>
                          </div>
                          <Button
                            className="m-0 mb-1 w-100"
                            color="secondary"
                            onClick={() => bgFileEl.current.click()}
                          >
                            <div className="btn-inner--icon">
                              <span className="btn-inner--text">Upload Images</span>
                            </div>
                          </Button>
                        </div>
                      </>
                    ): null}
                  </Row> */}
                  <h4>Product Details</h4>
                  <Card className="shadow mb-4">
                    <CardBody className="bg-product m-4 rounded">
                      <div className="product-max-width">

                      <Row>
                        <Col md="6">
                          <div className="mb-2">
                            <small className="font-weight-bold">
                              Data Source
                            </small>
                          </div>
                          <FormGroup className="text-center">
                            <UncontrolledDropdown nav inNavbar className="w-100">
                              <DropdownToggle caret color="default" className="w-100 text-uppercase">
                                {
                                  (values.source?.length > 20 && values.source?.indexOf(' ') <= 0)?
                                  env?.renderSourceName((productGroups?.find(x => x._id === values.source)?.groupName)?.toLowerCase())
                                  :
                                  env?.renderSourceName((values.source?values.source.replace(/([A-Z])/g, ' $1').trim(): '-')?.toLowerCase())
                                }
                              </DropdownToggle>
                              <DropdownMenu className="w-100">
                                <div className="editInputs-colors-scrollbar mac-scrollbar">
                                  {
                                    productGroups.map((x, i) => {
                                      return (
                                        <DropdownItem 
                                          key={i}
                                          className="text-capitalize" 
                                          value={x._id} 
                                          onClick={() => {
                                            setFieldValue('source', x._id)
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                        >
                                          {x.groupName}
                                        </DropdownItem>
                                      )
                                    })
                                  }
                                   <DropdownItem 
                                      className="text-capitalize" 
                                      value={'csv'} 
                                      onClick={() => {
                                        setFieldValue('source', 'csv')
                                        setItems([])
                                        setAdditionOption([])
                                      }}
                                    >
                                      CSV
                                    </DropdownItem>
                                    {
                                      props.role === 'superadmin' && (
                                        <>
                                        <DropdownItem 
                                          className="text-capitalize" 
                                          value={'api'} 
                                          onClick={() => {
                                            setFieldValue('source', 'api')
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                        >
                                          Excard (Api)
                                        </DropdownItem>
                                        {/* <DropdownItem 
                                          className="text-capitalize" 
                                          value={'api-sg'} 
                                          onClick={() => {
                                            setFieldValue('source', 'api-sg')
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                        >
                                          Excard SG (Api)
                                        </DropdownItem>
                                        <DropdownItem 
                                          className="text-capitalize" 
                                          value={'api-east'} 
                                          onClick={() => {
                                            setFieldValue('source', 'api-east')
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                        >
                                          Excard East (Api)
                                        </DropdownItem> */}
                                        </>
                                      )
                                    }
                                    {
                                      props.userInfo?.avaiSources?.sticker && 
                                      (
                                        <DropdownItem 
                                          className="text-capitalize" 
                                          value={'sticker'} 
                                          onClick={() => {
                                            setFieldValue('source', 'sticker')
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                        >
                                          SC-Sticker
                                        </DropdownItem>
                                      )
                                    }
                                    {
                                      props.userInfo?.avaiSources?.poster && 
                                      (
                                        <DropdownItem 
                                          className="text-capitalize" 
                                          value={'poster'} 
                                          onClick={() => {
                                            setFieldValue('source', 'poster')
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                        >
                                          SC-Poster
                                        </DropdownItem>
                                      )
                                    }
                                    {
                                      props.userInfo?.avaiSources?.looseSheet && 
                                      (
                                        <>
                                          {/* <DropdownItem 
                                            className="text-capitalize" 
                                            value={'loose-sheet'} 
                                            onClick={() => {
                                              setFieldValue('source', 'loose-sheet')
                                              setItems([])
                                              setAdditionOption([])
                                            }}
                                          >
                                            SC-Loose Sheet
                                          </DropdownItem> */}
                                          <DropdownItem 
                                          className="text-capitalize" 
                                          value={'loose-sheet'} 
                                          onClick={() => {
                                            setFieldValue('source', 'loose-sheet-v2')
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                          >
                                          SC-Loose Sheet
                                        </DropdownItem>
                                      </>
                                      )
                                    }
                                    {
                                      props.userInfo?.avaiSources?.certificate && 
                                      (
                                        <DropdownItem 
                                          className="text-capitalize" 
                                          value={'certificate'} 
                                          onClick={() => {
                                            setFieldValue('source', 'certificate')
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                        >
                                          SC-Certificate
                                        </DropdownItem>
                                      )
                                    }
                                    {
                                      props.userInfo?.avaiSources?.document && 
                                      (
                                        <DropdownItem 
                                          className="text-capitalize" 
                                          value={'document'} 
                                          onClick={() => {
                                            setFieldValue('source', 'document')
                                            setItems([])
                                            setAdditionOption([])
                                          }}
                                        >
                                          SC-Document
                                        </DropdownItem>
                                      )
                                    }
                                    {
                                    props.userInfo?.avaiSources?.booklet && 
                                    (
                                      <DropdownItem 
                                        className="text-capitalize" 
                                        value={'booklet'} 
                                        onClick={() => {
                                          setFieldValue('source', 'booklet')
                                          setItems([])
                                          setAdditionOption([])
                                        }}
                                      >
                                        SC-Booklet
                                      </DropdownItem>
                                    )
                                  }
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </FormGroup>
                        </Col>
                        {
                          props.role === 'superadmin'? (
                            <>
                              <Col md="6">
                                <div className="mb-2">
                                  <small className=" font-weight-bold">
                                    Supplier
                                  </small>
                                </div>
                                <FormGroup className="text-center">
                                  <UncontrolledDropdown nav inNavbar className="w-100">
                                    <DropdownToggle caret color="default" className="w-100 text-capitalize">
                                      {values.supplier?values.supplier: '-'}
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100">
                                      <div className="editInputs-colors-scrollbar mac-scrollbar">
                                        {
                                          env.suppliers.map((x, i) => {
                                            return (
                                              <DropdownItem 
                                                key={i}
                                                className="text-uppercase" 
                                                value={x} 
                                                onClick={() => {
                                                  setFieldValue('supplier', x)
                                                }}
                                              >
                                                {x}
                                              </DropdownItem>
                                            )
                                          })
                                        }
                                      </div>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </FormGroup>
                              </Col>
                            </>
                          ): null
                        }
                        {
                          (values.source === 'api' || values.source === 'api-sg'|| values.source === 'api-east') &&
                          (
                            <Col md="12">
                              <div className="mb-2">
                                <small className=" font-weight-bold">
                                  Product
                                </small>
                              </div>
                              <FormGroup className="text-center">
                                <UncontrolledDropdown nav inNavbar className="w-100">
                                  <DropdownToggle caret color="secondary" className="w-100 ">
                                    {values.apiType}
                                  </DropdownToggle>
                                  <DropdownMenu className="w-100">
                                    <div className="editInputs-colors-scrollbar mac-scrollbar">
                                      {
                                        (apiType).sort().map((x, i) => {
                                          return(
                                            <DropdownItem 
                                              key={i}
                                              className="text-capitalize" 
                                              value={x} 
                                              onClick={() => setFieldValue('apiType', x)}
                                            >
                                              {capitalize(x).replace('||', ' - ')}
                                            </DropdownItem>
                                          )
                                        })
                                      }
                                    </div>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </FormGroup>
                            </Col>
                          )
                          }
                          {
                          (values.source?.length > 20 && values.source?.indexOf(' ') <= 0) &&
                          (
                            <Col md="12">
                              <div className="mb-2">
                                <small className=" font-weight-bold">
                                  Product
                                </small>
                              </div>
                              <FormGroup className="text-center">
                                <UncontrolledDropdown nav inNavbar className="w-100">
                                  <DropdownToggle caret color="secondary" className="w-100 ">
                                    {values.groupType}
                                  </DropdownToggle>
                                  <DropdownMenu className="w-100">
                                    <div className="editInputs-colors-scrollbar mac-scrollbar">
                                      {
                                        (productGroups?.find(x => x._id === values.source)?.productIds)?.sort(function(a, b){
                                          if(a.name < b.name) { return -1; }
                                          if(a.name > b.name) { return 1; }
                                          return 0;
                                          }).map((x, i) => {
                                          return(
                                            <DropdownItem 
                                              key={i}
                                              className="text-capitalize" 
                                              value={x.name} 
                                              onClick={() => setFieldValue('groupType', x.name)}
                                            >
                                              {x.name}
                                            </DropdownItem>
                                          )
                                        })
                                      }
                                    </div>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </FormGroup>
                            </Col>
                          )
                        }
                      </Row>
                      
                      <Row>
                        <Col md="12">
                          <div className="mb-2">
                            <small className="font-weight-bold">
                              Product Name
                            </small>
                          </div>
                          <FormGroup
                            className={classnames("mb-3", {
                              focused: nameFocused
                            }, {
                              "has-danger": errors.name && touched.name
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Product Name"
                                type="text"
                                id="name"
                                value={values.name}
                                onChange={handleChange}
                                onFocus={e => setNameFocused(true)}
                                onBlur={e => {
                                  setNameFocused(false);
                                  handleBlur(e)
                                }}
                              />
                            </InputGroup>
                            {errors.name && touched.name && (
                              <div className="input-feedback">{errors.name}</div>
                            )}
                          </FormGroup>
                        </Col>
                        {/* <Col md="6">
                          <div className="mb-2">
                            <small className=" font-weight-bold">
                              Product Code
                            </small>
                          </div>
                          <FormGroup
                            className={classnames("mb-3", {
                              focused: codeFocused
                            }, {
                              "has-danger": errors.code && touched.code
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fa fa-qrcode"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Product Code"
                                type="text"
                                id="code"
                                rows="3"
                                value={values.code}
                                onChange={handleChange}
                                onFocus={e => setCodeFocused(true)}
                                onBlur={e => {
                                  setCodeFocused(false);
                                  handleBlur(e)
                                }}
                              />
                            </InputGroup>
                            {errors.code && touched.code && (
                              <div className="input-feedback">{errors.code}</div>
                            )}
                          </FormGroup>
                        </Col> */}
                      </Row>

                      {
                        values.source === 'csv' || values.source === 'csv-prefix'? (
                          <>
                            <UploadFile 
                              csvFile={csvFile}
                              uploadFileEl={uploadFileEl}
                              handleFileChange={handleFileChange} 
                              progress={progress}
                              setCsvFile={setCsvFile}
                              values={values}
                            />
                          </>
                        ): null
                      }

                        <FormGroup>
                          <div className="mb-2">
                            <small className=" font-weight-bold">
                              Description
                            </small>
                          </div>
                          <Input
                            className="form-control-alternative"
                            placeholder="Description"
                            id="description"
                            value={values.description}
                            onChange={handleChange}
                            autoComplete="off"
                            type="textarea"
                            rows="3"
                          />
                        </FormGroup>

                      </div>
                    </CardBody>
                  </Card>

                  {/* temporary */}
                  {
                    props.role === 'superadmin' &&
                    (values.source === 'csv' || values.source === 'csv-prefix')? (
                      <>
                        <CSVForm values={values} />
                        <CSVAdditionalOptions values={values} currency={props.currency} />
                      </>
                    ): null
                  }

                  {
                    values.source === 'certificate' || values.source === 'document' ?
                    (
                      <DocumentForm 
                        amountFocused={amountFocused}
                        setAmountFocused={setAmountFocused}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        items={items}
                        setItems={setItems}
                        manualCostBetween={manualCostBetween} 
                        setManualCostBetween={setManualCostBetween}
                        manualCostGreater={manualCostGreater} 
                        setManualCostGreater={setManualCostGreater}
                        manualCostDefault={manualCostDefault}
                        setManualCostDefault={setManualCostDefault}
                        source={values.source}
                        currency={props.currency}
                      />
                    ): null
                  }

                  {
                    (values.source) && values.source.includes('booklet') ?
                    (
                      <BookletForm 
                        amountFocused={amountFocused}
                        setAmountFocused={setAmountFocused}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        items={items}
                        setItems={setItems}
                        manualCostBetween={manualCostBetween} 
                        setManualCostBetween={setManualCostBetween}
                        manualCostGreater={manualCostGreater} 
                        setManualCostGreater={setManualCostGreater}
                        manualCostDefault={manualCostDefault}
                        setManualCostDefault={setManualCostDefault}
                        source={values.source}
                        currency={props.currency}
                      />
                    ): null
                  }
                  
                  {
                    values.source === 'sticker' || values.source === 'loose-sheet' ?
                    (
                      <StickerForm 
                        amountFocused={amountFocused}
                        setAmountFocused={setAmountFocused}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        items={items}
                        setItems={setItems}
                        manualCostBetween={manualCostBetween} 
                        setManualCostBetween={setManualCostBetween}
                        manualCostGreater={manualCostGreater} 
                        setManualCostGreater={setManualCostGreater}
                        manualCostDefault={manualCostDefault}
                        setManualCostDefault={setManualCostDefault}
                        source={values.source}
                        currency={props.currency}
                        setFieldValue={setFieldValue}
                      />
                    ): null
                  }

{
                    values.source === 'loose-sheet-v2' ?
                    (
                      <LooseSheetForm 
                        amountFocused={amountFocused}
                        setAmountFocused={setAmountFocused}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        items={items}
                        setItems={setItems}
                        manualCostBetween={manualCostBetween} 
                        setManualCostBetween={setManualCostBetween}
                        manualCostGreater={manualCostGreater} 
                        setManualCostGreater={setManualCostGreater}
                        manualCostDefault={manualCostDefault}
                        setManualCostDefault={setManualCostDefault}
                        source={values.source}
                        currency={props.currency}
                        setFieldValue={setFieldValue}
                      />
                    ): null
                  }

                  {
                    values.source === 'poster' ?
                    (
                      <PosterForm 
                        amountFocused={amountFocused}
                        setAmountFocused={setAmountFocused}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        items={items}
                        setItems={setItems}
                        currency={props.currency}
                      />
                    ): null
                  }

                  {/* For all calculator */}
                  {
                  values.source === 'sticker'
                  || values.source === 'poster' 
                  || values.source === 'loose-sheet' 
                  || values.source === 'certificate'
                  || values.source === 'document'?
                  (
                    <AdditionalOptions
                      additionOption={additionOption}
                      setAdditionOption={setAdditionOption}
                      currency={props.currency}
                    />
                    ): null
                  }

                  {(values.source === 'sticker'
                  || values.source === 'poster' 
                  || values.source === 'loose-sheet' 
                  || values.source === 'loose-sheet-v2' 
                  || values.source === 'certificate'
                  || values.source === 'booklet'
                  || values.source === 'document'
                  || values.source === 'csv'
                  ) && ( (props.role === 'superadmin') || (!values.customQuantity.isAdminSet)
                  ) && (
                  <CustomQuantity
                    values={values}
                    role={props.role}
                    disableFields={(props.role === 'superadmin') ? false : values.customQuantity.isAdminSet}
                    source={values.source}
                  />
                  )
                  }

                  {
                    ((props.role === 'superadmin' || !dataEdit.superDynamicPriceId) && checkDynamicPriceEnabled(source))? (
                      <>
                        <ProductAssignDynamicPrice 
                          dynamicPriceName={dynamicPriceName}
                          setDynamicPriceName={setDynamicPriceName}
                          dynamicPriceData={props.dynamicPriceData}
                        />
                      </>
                    ): null
                  }

                  {
                    props.role !== 'superadmin'? (
                      <>
                        <Card className="shadow mb-4">
                          <CardBody>
                            <small className=" font-weight-bold">
                              Profit Margin
                            </small>
                            <Row>
                              <Col md="12" className="text-center">
                                <Input 
                                  className="mr-2 w-auto d-inline-block"
                                  value={props.marginsData.find(e => e._id === marginName)? props.marginsData.find(e => e._id === marginName).marginName: ""} 
                                  disabled
                                />
                                <UncontrolledDropdown nav inNavbar>
                                  <DropdownToggle caret color="secondary" className="">
                                    Apply Margin
                                  </DropdownToggle>
                                  <DropdownMenu className="w-100">
                                    <div className="editInputs-colors-scrollbar mac-scrollbar">
                                      <DropdownItem 
                                        className="bg-danger text-white text-capitalize" 
                                        value={''} 
                                        onClick={() => {
                                          setMarginName('')
                                        }}
                                      >
                                        Unlink Margin
                                      </DropdownItem>
                                      {
                                        props.marginsData.map((x, i) => {
                                          return (
                                            <DropdownItem
                                              key={i} 
                                              className="text-capitalize" 
                                              value={x.marginName} 
                                              onClick={() => {
                                                setMarginName(x._id)
                                              }}
                                            >
                                              {x.marginName}
                                            </DropdownItem>
                                          )
                                        })
                                      }
                                    </div>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                <br/>
                                <br/>
                                <p>*To edit margin, Please go to margin page to edit and apply again</p>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </>
                    ): null
                  }

                  {/* <ArtworkOption 
                    values={values}
                    handleChange={handleChange}
                  /> */}

                  {
                    props.role !== 'superadmin'? (
                      <>
                        <ArtworkServices 
                          values={values}
                          currency={props.currency}
                        />
                        <AdditionalCalc 
                          timelineArray={timelineArray}
                          setTimelineArray={setTimelineArray}
                          marginsData={props.marginsData}
                          currency={props.currency}
                        />
                      </>
                    ): null
                  }

                  {/* <div className="mt-4">
                    <ProductDesc 
                      productDetail={productDetail}
                      setProductDetail={setProductDetail}
                    />
                  </div> */}

                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                      props.history.push(`/${props.role}/`)
                      removeFile()
                    }}
                  >
                    Discard
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                    disabled={loading}
                  >
                    {loading? (
                      <div className="spinner-border mr-1" role="status" style={{height: '1rem', width: '1rem'}}>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ):null}
                    Confirm
                  </Button>
                </div>
              </Form>
              );
            }}
          </Formik>
        {/* </Drawer> */}
      {/* </Modal> */}
    </>
  );
}
 
export default withRouter(ProductModalEdit);