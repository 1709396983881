import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Card,
    CardBody,
    Row,
    Col,
    // Table
} from 'reactstrap';
import { DynamicPriceLastConditionEnum } from '../../entities';

const DynamicPriceCalc = ({
    dynamicPriceBetween,
    dynamicPriceLastCondition,
    setDynamicPriceBetween,
    dynamicPriceBetweenOnChange,
    removeDynamicPriceBetween,

    dynamicPriceData,
    setDynamicPriceLastCondition,
    clone
}) => {
    const applyDynamicPrice = (value) => {
        clone(value.dynamicPriceBetween);
    };

    const addCondition = () => {
        if(dynamicPriceBetween[dynamicPriceBetween.length -1]){
            setDynamicPriceBetween(dynamicPriceBetween.concat({
                quantity: 0,
                percent: 0
            }));
        }else{
            setDynamicPriceBetween(dynamicPriceBetween.concat({
                quantity: 0,
                percent: 0
            }).concat({
                quantity: 0,
                percent: 0,
                type: 0
            }));
        }
    };

    const lastTwoInput = (e, i) => {
        let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);

        cloneDynamicPriceBetween[i]['quantity'] = Number(e.target.value);

        if(dynamicPriceBetween.length -2 === i){
            if(e.target.value){
                cloneDynamicPriceBetween[i+1]['quantity'] = parseFloat(e.target.value) + 1; 
            }else{
                cloneDynamicPriceBetween[i+1]['quantity'] = 1;
            }
        }

    
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const performQuantityCheck = (event, i) => {
        if(i > 0 && event.target.value < dynamicPriceBetween[i-1].quantity) {
            let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);

            cloneDynamicPriceBetween[i].quantity = cloneDynamicPriceBetween[i-1].quantity + 1;
            setDynamicPriceBetween(cloneDynamicPriceBetween);
        }
    };

    return (  
        <>
            <Card className='shadow'>
                <CardBody>
                    <UncontrolledDropdown nav inNavbar className='mb-2'>
                        <DropdownToggle caret color='secondary' className='text-uppercase'>
                            Clone Dynamic Price
                        </DropdownToggle>
                        <DropdownMenu className='w-100'>
                            <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                {
                                    dynamicPriceData.map((x, i) => {
                                        return (
                                            <DropdownItem
                                                key={i} 
                                                className='text-capitalize' 
                                                value={x.dynamicPriceName} 
                                                onClick={() => {
                                                    applyDynamicPrice(x);
                                                }}
                                            >
                                                {x.dynamicPriceName}
                                            </DropdownItem>
                                        );
                                    })
                                }
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className='mb-2'>
                        <Row className='mb-2'>
                            <Col md='2'>
                            </Col>
                            <Col md='3'>
                                <small className='font-weight-bold'>
                                    Max Quantity
                                </small>
                            </Col>
                            <Col md='1'>
                            </Col>
                            <Col md='1'>
                            </Col>
                            <Col md='4'>
                                <small className='font-weight-bold'>
                                    Discount from base cost (%)
                                </small>
                            </Col>
                            <Col md='1'>
                            </Col>
                        </Row>
                        {
                            dynamicPriceBetween.length > 0? 
                                dynamicPriceBetween.map((x, i) => {
                                    return (
                                        <Row key={i} className='mb-2'>
                                            <Col md='2'>
                                                If quantity is
                                            </Col>
                                            <Col md='3'>
                                                <InputGroup className='input-group-alternative'>
                                                    <Input
                                                        className='pl-2'
                                                        placeholder='Quantity'
                                                        type='number'
                                                        bsSize='sm'
                                                        min='0'
                                                        step='1'
                                                        value={Number(x.quantity).toString()}
                                                        onChange={(e) => lastTwoInput(e, i)}
                                                        onBlur={(event) => performQuantityCheck(event, i)}
                                                        onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                </InputGroup>
                                            </Col>
                                            {(dynamicPriceBetween.length === i+1)? (
                                                <Col md='2'>
                                                    <UncontrolledDropdown nav inNavbar className='mb-2 w-100'>
                                                        <DropdownToggle caret color='secondary' className='w-100'>
                                                            {DynamicPriceLastConditionEnum[dynamicPriceLastCondition]}
                                                        </DropdownToggle>
                                                        <DropdownMenu className='w-100'>
                                                            <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                                                <DropdownItem
                                                                    key={0} 
                                                                    className='text-capitalize' 
                                                                    value={0} 
                                                                    onClick={() => {
                                                                        setDynamicPriceLastCondition(0);
                                                                    }}
                                                                >
                                                                    {DynamicPriceLastConditionEnum[0]}
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    key={1} 
                                                                    className='text-capitalize' 
                                                                    value={1} 
                                                                    onClick={() => {
                                                                        setDynamicPriceLastCondition(1);
                                                                    }}
                                                                >
                                                                    {DynamicPriceLastConditionEnum[1]}
                                                                </DropdownItem>
                                                            </div>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </Col>
                                            ): (
                                                <>
                                                    <Col md='2'><p className='text-sm font-weight-normal'>{'& below, then less'}</p></Col>
                                                </>
                                            )}
                                            <Col md='4'>
                                                <InputGroup className='input-group-alternative'>
                                                    <Input
                                                        placeholder='Percent Charge(%)'
                                                        type='number'
                                                        bsSize='sm'
                                                        min='0'
                                                        value={Number(x.percent).toString()}
                                                        onChange={(e) => dynamicPriceBetweenOnChange(Number(e.target.value), 'percent', i)}
                                                        onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                    <InputGroupAddon addonType='append' className='bg-secondary'>
                                                        <InputGroupText className='bg-transparent'>
                                                            <span>%</span>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            <Col md='1' className='d-flex justify-content-end align-items-center'>
                                                <Button
                                                    color='secondary'
                                                    type='button'
                                                    size='sm'
                                                    onClick={() => removeDynamicPriceBetween(i)}
                                                >
                                                    <div className='btn-inner--text text-red'>
                                                        <i 
                                                            className='fa fa-minus'
                                                        />
                                                    </div>
                                                </Button>
                                            </Col>
                                        </Row>
                                    );
                                }): null
                        }
                    </div>
                    <Row>
                        <Col md='12'>
                            <a 
                                href='/' 
                                onClick={ (event) => {
                                    event.preventDefault();
                                    addCondition();
                                }} 
                            >
                                <span>
                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                    Add new line
                                </span>
                            </a>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};
 
export default DynamicPriceCalc;