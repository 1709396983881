import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Card,
    CardBody,
    Row,
    Col,
    // Table
} from 'reactstrap';

const MarginCalc = ({
    marginBetween,
    setMarginBetween,
    marginBetweenOnChange,
    removeMarginBetween,

    marginsData,
    currency
}) => {

    const applyMargin = (value) => {
        setMarginBetween(value.marginBetween);
    };

    const addCondition = () => {
        if(marginBetween[marginBetween.length -1]){
            // setMarginBetween(marginBetween.concat({
            //   maxAmt: (parseFloat(marginBetween[marginBetween.length -1].maxAmt) + 0.0001).toFixed(4),
            //   percent: 0
            // }))
            setMarginBetween(marginBetween.concat({
                maxAmt: 0,
                percent: 0
            }));
        }else{
            setMarginBetween(marginBetween.concat({
                maxAmt: 0,
                percent: 0
            }).concat({
                maxAmt: 0,
                percent: 0
            }));
        }
    };

    const lastTwoInput = (e, i) => {
        let cloneMarginBetween = cloneDeep(marginBetween);

        cloneMarginBetween[i]['maxAmt'] = Number(e.target.value);

        if(marginBetween.length -2 === i){
            if(e.target.value){
                cloneMarginBetween[i+1]['maxAmt'] = (parseFloat(e.target.value) + 0.0001).toFixed(4); 
            }else{
                cloneMarginBetween[i+1]['maxAmt'] = (0.0001).toFixed(4);
            }
        }

    
        setMarginBetween(cloneMarginBetween);
    };

    return (  
        <>
            <Card className='shadow'>
                <CardBody>
                    <h4>
                        Profit Margin
                    </h4>
                    <UncontrolledDropdown nav inNavbar className='mb-2'>
                        <DropdownToggle caret color='secondary' className='text-uppercase'>
                            Clone Margin
                        </DropdownToggle>
                        <DropdownMenu className='w-100'>
                            <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                {
                                    marginsData.map((x, i) => {
                                        return (
                                            <DropdownItem
                                                key={i} 
                                                className='text-capitalize' 
                                                value={x.marginName} 
                                                onClick={() => {
                                                    applyMargin(x);
                                                }}
                                            >
                                                {x.marginName}
                                            </DropdownItem>
                                        );
                                    })
                                }
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className='mb-2'>
                        <Row className='mb-2'>
                            <Col md='1'>
                            </Col>
                            <Col md='4'>
                                <small className='font-weight-bold'>
                                    Max Amt. ({currency?currency:'MYR'})
                                </small>
                            </Col>
                            <Col md='1'>
                            </Col>
                            <Col md='1'>
                            </Col>
                            <Col md='4'>
                                <small className='font-weight-bold'>
                                    Profit Margin (%)
                                </small>
                            </Col>
                            <Col md='1'>
                            </Col>
                        </Row>
                        {
                            marginBetween.length > 0? 
                                marginBetween.map((x, i) => {
                                    return (
                                        <Row key={i} className='mb-2'>
                                            <Col md='1'>
                                                {marginBetween.length === i+1? (
                                                    <>
                                                        {'Above'} ({currency?currency:'MYR'})
                                                    </>
                                                ): (
                                                    <>
                                                        {/* {(i+1)}. */}
                                                        {currency?currency:'MYR'}
                                                    </>
                                                )}
                                            </Col>
                                            <Col md='4'>
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend' className='bg-secondary'>
                                                        <InputGroupText className='bg-transparent'>
                                                            <span className='text-uppercase'>{currency}</span>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        className='pl-2'
                                                        placeholder='Amount Charge'
                                                        type='number'
                                                        bsSize='sm'
                                                        min='0'
                                                        step='.01'
                                                        value={Number(x.maxAmt).toString()}
                                                        disabled={marginBetween.length === i+1?true:false}
                                                        onChange={(e) => lastTwoInput(e, i)}
                                                        onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                </InputGroup>
                                            </Col>
                                            {marginBetween.length === i+1? (
                                                <Col md='1'></Col>
                                            ): (
                                                <>
                                                    <Col md='1'><p className='text-sm font-weight-normal'>{'& below'}</p></Col>
                                                </>
                                            )}
                                            <Col md='1'><p className='text-sm font-weight-normal'>Add</p></Col>
                                            <Col md='4'>
                                                <InputGroup className='input-group-alternative'>
                                                    <Input
                                                        placeholder='Percent Charge(%)'
                                                        type='number'
                                                        bsSize='sm'
                                                        min='0'
                                                        value={Number(x.percent).toString()}
                                                        onChange={(e) => marginBetweenOnChange(Number(e.target.value), 'percent', i)}
                                                        onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                    <InputGroupAddon addonType='append' className='bg-secondary'>
                                                        <InputGroupText className='bg-transparent'>
                                                            <span>%</span>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            <Col md='1' className='d-flex justify-content-end align-items-center'>
                                                <Button
                                                    color='secondary'
                                                    type='button'
                                                    size='sm'
                                                    onClick={() => removeMarginBetween(i)}
                                                >
                                                    <div className='btn-inner--text text-red'>
                                                        <i 
                                                            className='fa fa-minus'
                                                        />
                                                    </div>
                                                </Button>
                                            </Col>
                                        </Row>
                                    );
                                }): null
                        }
                    </div>
                    <Row>
                        <Col md='12'>
                            <a 
                                href='/' 
                                onClick={ (event) => {
                                    event.preventDefault();
                                    addCondition();
                                }} 
                            >
                                <span>
                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                    Add new line
                                </span>
                            </a>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};
 
export default MarginCalc;