/*eslint-disable*/
import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

import { withRouter } from 'react-router'
import {connect} from 'react-redux';
import { logout } from '../../redux/actions/roleActions';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import client from "../../feathers"

var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const logout = () => {
    client.authenticate()
    .then((a) => {
      client.logout()
      props.logout()
      props.history.push('/auth/login')
    })
    .catch((err) => {
      props.logout()
      props.history.push('/auth/login')
    })
  }

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if(prop.show && props.layout === prop.layout){
        if(prop.path === '/auth/login'){
          return (
            <>
              <NavItem key={key} className="px-4">
                <NavLink
                  href={props.companyInfo.blogUrl}
                  target="_blank"rel="noopener noreferrer"
                  className="cursor-pointer"
                >
                  <i className="ni ni-book-bookmark" />
                  Blog
                </NavLink>
              </NavItem>
              <NavItem key={key} className="px-4">
                <NavLink
                  // to={prop.layout + prop.path}
                  // tag={NavLinkRRD}
                  onClick={logout}
                  className="cursor-pointer"
                  // activeClassName="active"
                  >
                    {
                      prop.icon?.includes('<svg')?
                      <div
                        className="mr-2"
                        dangerouslySetInnerHTML={{__html: prop.icon}}
                      />
                      :
                      <i className={prop.icon} />
                    }
                  {prop.name}
                </NavLink>
              </NavItem>
            </>
          );
        }else{
          return (
            <NavItem key={key} className="px-4">
              <NavLink
                to={prop.path?.includes("https://")? props.path: prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
                >
                  {
                    prop.icon?.includes('<svg')?
                    <div
                      className="mr-2"
                      dangerouslySetInnerHTML={{__html: prop.icon}}
                    />
                    :
                    <i className={prop.icon} />
                  }
                {prop.name}
              </NavLink>
            </NavItem>
          );
        }
      }
    });
  };

  var { bgColor, routes, logo, currency } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  if(currency === 'sgd'){
    routes = routes.filter(item => item.path !== "/csv")
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light"
      expand="md"
      id="sidenav-main"
      style={{backgroundColor: 'white'}}
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={props.logoUrl ? `${process.env.REACT_APP_API_URL}images/${props.logoUrl}` : logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          {/* <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  {/* <img
                    alt="..."
                    src={
                      require("../../assets/img/theme/team-4-800x800.jpg")
                        .default
                    }
                  /> */}
                  <div
                    className="img rounded-circle overflow-hidden bg-color d-inline-block"
                    style={{ height: '100%', verticalAlign: 'middle', borderStyle: 'none' }}
                  >
                    <img
                      alt="entreship sol"
                      className="w-100"
                      src={(props.userInfo.profileImage?props.userInfo.profileImage.fileUrl: "")? props.userInfo.profileImage.fileUrl: "ship.png"}
                    />
                  </div>
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    {
                      props.userInfo.name?props.userInfo.name:
                      (
                        (props.userInfo.firstName?props.userInfo.firstName:"")
                        + " " + 
                        (props.userInfo.lastName?props.userInfo.lastName:"")
                        + " "
                      )
                    }
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to={"/admin/user-profile"} tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={logout}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          
          {/* Divider */}
          <hr className="my-3" />
          {
          (props.role) && props.role === 'admin' && (
            <NavLink
              href={`/admin/livechat`}
              className="cursor-pointer"
            >
              <div className="d-flex">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                  </svg>
                </div>
                <div className="ml-1">
                  <h4 className="mb-0">Live Chat Support</h4>
                  <p className="mb-0 font-weight-normal lh-10 text-xs">{props.companyInfo.supportText}</p>
                </div>
              </div>
            </NavLink>
          )
          }

          {/* {
          props.role === 'superadmin' &&
          (
            <Card className="shadow">
              <CardBody className="text-center">
                <p className="m-0 font-weight-normal">
                  Conversion rate <strong>MYR</strong> to <strong>SGD</strong>
                </p>
                <h1 className="display-2">{props.rateMYRtoSGD}</h1>
                <Button
                  color="secondary"
                  className="w-100"
                  href="/superadmin/setting"
                >
                  Edit Rate
                </Button>
              </CardBody>
            </Card>
          )
        } */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = state => ({
  auth: state.role.auth,
  username: state.role.details.user?state.role.details.user.username:'',
  userInfo: state.role.details.user?state.role.details.user:{},
  currency: state.role.details.user?state.role.details.user.currency?state.role.details.user.currency:'myr':'myr',
  rateMYRtoSGD: state.company.data.rateMYRtoSGD?state.company.data.rateMYRtoSGD:4.13,
  logoUrl: state.company.data.logoUrl,
  role: state.role.details.user?state.role.details.user.role:'',
  companyInfo: state.company.data,
});

const mapDispatchToProps = {
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));