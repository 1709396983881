export default [
    'Loose Sheet||Litho Offset',
    'ARCH FILE',
    'BANNER',
    'Bunting',
    'BUTTON BADGE',
    'LETTER HEAD',
    'BOOKMARK',
    'CALENDAR',
    'CALENDAR||Soft Stand',
    'CALENDAR||Hard Stand',
    'CALENDAR||Wall',
    'CALENDAR||Wire-o Wall',
    'Hand Fan',
    'Hard Cover Menu',
    'KAD KAHWIN',
    'Hanger',
    'Label Sticker',
    
    'Loose Sheet||Digital OffSet',
    'COMPUTER FORM',
    'Tote Bag',
    'Roll Up Stand',
    'PVC CARD',
    'Mug',
    'Pillow',
    'Non Woven Bag',
    'Folder',
    'Folder||Presentation',
    'Folder||Document',
    'Folder||Key',
    'Folder||CD',
    'Money Packet',
    'ROLL FORM STICKER',
    'PAPAN KOPI',
    'Magnet',

    'Business card',
    'Business card||Standard',
    'Business card||Folded',
    'Business card||Custom Die-Cut',
    'BOOKLET',
    'BOOKLET||Hard Cover',
    'BOOKLET||Saddle Stitch',
    'BOOKLET||Perfect Binding',
    'LABEL STICKER WITH HOT STAMPING',
    'Mask Keeper',
    'L SHAPE FOLDER',
    'PAPER BAG',
    'ENVELOPE',
    'BILL BOOK',
    'Voucher',
    'Car Sticker',
    'Wobbler',
    'Tent Card',
    'Standing Pouch',
    'UV DTF Sticker',
    'Kotak Cenderahati',
    'Notepad',
    'Perfect Bind Notebook',
    'Wire-O Notebook',
    'Greeting Card'
];