import React, { useState } from 'react';
import ProductModal from '../components/Products/ProductModal';
import { connect } from 'react-redux';
import { fetchMargins } from '../redux/actions/marginsActions';
import { fetchDynamicPrice } from '../redux/actions/dynamicPriceActions';
import { fetchProducts, pushProducts, updateProducts, moveProducts, removeProducts } from '../redux/actions/productsActions';
import getSymbolFromCurrency from 'currency-symbol-map';

import Notifications from '../components/Extra/Notifications.js';

const ProductsCreate = (props) => {
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);

    const notificationOpen = (bool, color, message) => {
        setIsOpen(bool);
        setColor(color);
        setMessage(message);
    };

    return (  
        <>
            <div className='header' style={{height: 64}}>
                <span className='mask' style={{backgroundColor: '#fff'}} />
            </div>
            <div className='mt-4'>
                <ProductModal 
                    // modalVisible={modalVisible} 
                    // toggleModal={toggleModal}
                    notificationOpen={notificationOpen} 
                    pushProducts={props.pushProducts}
                    currency={props.currency}
                    rateMYRtoSGD={props.rateMYRtoSGD}
                    marginsData={props.marginsData}
                    dynamicPriceData={props.dynamicPriceData}
                    role={props.role}
                    userId={props.userId}
                    userInfo={props.userInfo}
                />
            </div>
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    role: state.role.details.user?state.role.details.user.role:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    userInfo: state.role.details.user?state.role.details.user:{},
    productsData: state.products.data,
    companyInfo: state.company.data,
    marginsData: state.margins.data,
    dynamicPriceData: state.dynamicPrice.data,
    rateMYRtoSGD: state.company.data.rateMYRtoSGD?state.company.data.rateMYRtoSGD:4.13,
});
  
const mapDispatchToProps = {
    fetchProducts: fetchProducts,
    pushProducts: pushProducts,
    updateProducts: updateProducts,
    moveProducts: moveProducts,
    removeProducts: removeProducts,
    fetchMargins: fetchMargins,
    fetchDynamicPrice: fetchDynamicPrice,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsCreate);