const suppliers = [
    'ec',
    'xp',
    'pe',
    'ep',
    'mem',
    'other'
];
const superadminSources = [
    {
        value: 'csv',
        display: 'CSV'
    },
    {
        value: 'api',
        display: 'Excard (Api)'
    },
    {
        value: 'api-sg',
        display: 'Excard SG (Api)'
    },
    {
        value: 'api-east',
        display: 'Excard East (Api)'
    },
    {
        value: 'sticker',
        display: 'SC-sticker'
    },
    {
        value: 'certificate',
        display: 'SC-certificate'
    },
    {
        value: 'document',
        display: 'SC-document'
    },
    {
        value: 'poster',
        display: 'SC-poster'
    },
    // {
    //     value: 'loose-sheet',
    //     display: 'SC-loose Sheet'
    // },
    {
        value: 'loose-sheet-v2',
        display: 'SC-loose Sheet',
    },
    {
        value: 'booklet',
        display: 'SC-booklet'
    },
    // {
    //   value: "csv-prefix",
    //   display: "csv-prefix"
    // },
    // "csv-prefix",
    // "sticker-prefix",
    // "certificate-prefix",
    // "document-prefix",
    // "poster-prefix",
    // "loose-sheet-prefix",
    // "api-prefix"
];

const sources = [
    {
        value: 'csv',
        display: 'CSV'
    },
    {
        value: 'sticker',
        display: 'SC-sticker'
    },
    {
        value: 'certificate',
        display: 'SC-certificate'
    },
    {
        value: 'document',
        display: 'SC-document'
    },
    {
        value: 'poster',
        display: 'SC-poster'
    },
    // {
    //     value: 'loose-sheet',
    //     display: 'SC-loose Sheet'
    // },
    {
        value: 'loose-sheet-v2',
        display: 'SC-loose Sheet'
    },
    {
        value: 'booklet',
        display: 'SC-booklet'
    },
];

const role = 'main'; // main, sup

const renderSourceName = (value) => {
    let result = value;
    let findItem = superadminSources.find(x => x.value === value);
    if(findItem){
        result = findItem.display;
    }
    return result;
};

const env = {
    suppliers: suppliers,
    superadminSources: superadminSources,
    sources: sources,
    role: role,
    renderSourceName: renderSourceName
};

export default env;