import React, { useRef, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from 'reactstrap';

import Notifications from '../components/Extra/Notifications.js';

import client from '../feathers';
import axios from 'axios';

const validationSchema = Yup.object().shape({
});

const CompanySetting = (props) => {
    const uploadFileEl = useRef();
    const [ csvFile, setCsvFile ] = useState({});
    const [ progress, setProgress ] = useState(0);
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);

    useEffect(() => {
        setLoading(true);
        client.authenticate()
            .then(()=>{
                return client.service('template-downloads').find({
                    query: {
                        userId: props.userId,
                        $limit: 1
                    }
                });
            })
            .then((res) => {
                setLoading(false);
                if(res.data.length > 0){
                    setData(res.data[0]);
                    setCsvFile(res.data[0].csvFile);
                }else{
                    setData({csvFile:{}});
                }
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    }, [props.userId]);

    const notificationOpen = (bool, color, message) => {
        setIsOpen(bool);
        setColor(color);
        setMessage(message);
    };

    const handleFileChange = async(e) => {
        setProgress(0);

        let singleFile = e.target.files[0];
        let fileType = (singleFile.type === 'application/vnd.ms-excel' || singleFile.type === 'text/csv');

        if(fileType){
            let newFileName = (Math.random().toString(36).substring(2, 15) + '-' + 
        singleFile.lastModified + '-' + 
        singleFile.name);
            let formData = new FormData();
            formData.append('file', singleFile, newFileName);

            let modified = formData.get('file');

            setCsvFile(modified);

            return e.target.value = null;
        }else{
            window.alert('Only Accept CSV File!');
            return false;
        }
    };

    const handleDeleteCSV = (fileName) => {
        axios({
            method: 'post',
            url: `${client.io.io.uri}deleteCSVTemplateLocal`,
            data: {fileName},
            config: { headers: {'Content-Type': 'application/json' }}
        })
            .then((res) => {
                return (res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
  
    const csvUpload = async(file) => {

        const formData = new FormData();
        formData.append('NAME', 'Fred');
        formData.append('file', file);

        return await axios({
            method: 'post',
            url: `${client.io.io.uri}uploadCSVTemplateLocal`,
            data: formData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
            .then((res) => {
                return (res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onSubmit = async(value) => {
        setLoading(true);
    
        if(csvFile.name){
            let productDataUrl = await csvUpload(csvFile);

            // if removed, fileName will be moved as well
            if(data.csvFile.fileName){
                handleDeleteCSV(data.csvFile.fileName);
            }
      
            value.csvFile = {};
            value.csvFile.fileUrl = productDataUrl;
            value.csvFile.fileName = csvFile.name;
        }
    
        // if state nothing => remove all file
        // get from record data.csvFile
        if(!csvFile.name && !csvFile.fileName){
            if(data.csvFile.fileName){
                handleDeleteCSV(data.csvFile.fileName);
            }
            value.csvFile = {};
        }

        if(data._id){
            client.authenticate()
                .then(()=>{
                    return client.service('template-downloads').patch(data._id, value);
                })
                .then((res) => {
                    notificationOpen(true, 'success', 'Template Created Successfully!');
                    // setCsvFile({})
                    setData(res);
                    setLoading(false);
                })
                .catch((err)=>{
                    setLoading(false);
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }else{
            client.authenticate()
                .then(()=>{
                    return client.service('template-downloads').create(value);
                })
                .then((res) => {
                    notificationOpen(true, 'success', 'Template  Edited Successfully!');
                    // setCsvFile({})
                    setData(res);
                    setLoading(false);
                })
                .catch((err)=>{
                    setLoading(false);
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

    const downloadTemplate = () => {
        window.location.href = `${client.io.io.uri}downloadCSVTemplate`;
    };

    return (
        <>
            {/* Page content */}
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col className='order-xl-1' xl='10'>
                        <div className='mt-7 product-max-width'>
                            <Card className='shadow mb-5'>
                                <CardHeader className='text-center'>
                                    <div>
                                        <div 
                                            className='d-flex justify-content-center align-items-center mx-auto mb-2'
                                            style={{
                                                width: 32,
                                                height: 32,
                                                borderRadius: 20,
                                                border: '1px solid #648EF7',
                                            }}><span>1</span></div>
                                        <h3>Download this template and edit</h3>
                                        <p className='mb-0' style={{color: '#919BA7'}}>(Upload the edited template to generate download link)</p>
                                    </div>
                                </CardHeader>
                                <CardBody className='text-center'>
                                    <Button
                                        color='primary'
                                        type='button'
                                        onClick={downloadTemplate}
                                    >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
                                                <path fillRule='evenodd' clipRule='evenodd' d='M12.5 2.25C12.6989 2.25 12.8897 2.32902 13.0303 2.46967C13.171 2.61032 13.25 2.80109 13.25 3V14.69L16.47 11.47C16.5387 11.3963 16.6215 11.3372 16.7135 11.2962C16.8055 11.2552 16.9048 11.2332 17.0055 11.2314C17.1062 11.2296 17.2062 11.2482 17.2996 11.2859C17.393 11.3236 17.4778 11.3797 17.549 11.451C17.6203 11.5222 17.6764 11.607 17.7141 11.7004C17.7518 11.7938 17.7704 11.8938 17.7686 11.9945C17.7668 12.0952 17.7448 12.1945 17.7038 12.2865C17.6628 12.3785 17.6037 12.4613 17.53 12.53L13.03 17.03C12.8894 17.1705 12.6988 17.2493 12.5 17.2493C12.3012 17.2493 12.1106 17.1705 11.97 17.03L7.47 12.53C7.39631 12.4613 7.33721 12.3785 7.29622 12.2865C7.25523 12.1945 7.23319 12.0952 7.23141 11.9945C7.22963 11.8938 7.24816 11.7938 7.28588 11.7004C7.3236 11.607 7.37974 11.5222 7.45096 11.451C7.52218 11.3797 7.60701 11.3236 7.7004 11.2859C7.79379 11.2482 7.89382 11.2296 7.99452 11.2314C8.09523 11.2332 8.19454 11.2552 8.28654 11.2962C8.37854 11.3372 8.46134 11.3963 8.53 11.47L11.75 14.69V3C11.75 2.80109 11.829 2.61032 11.9697 2.46967C12.1103 2.32902 12.3011 2.25 12.5 2.25ZM3.5 15.75C3.69891 15.75 3.88968 15.829 4.03033 15.9697C4.17098 16.1103 4.25 16.3011 4.25 16.5V18.75C4.25 19.1478 4.40804 19.5294 4.68934 19.8107C4.97064 20.092 5.35218 20.25 5.75 20.25H19.25C19.6478 20.25 20.0294 20.092 20.3107 19.8107C20.592 19.5294 20.75 19.1478 20.75 18.75V16.5C20.75 16.3011 20.829 16.1103 20.9697 15.9697C21.1103 15.829 21.3011 15.75 21.5 15.75C21.6989 15.75 21.8897 15.829 22.0303 15.9697C22.171 16.1103 22.25 16.3011 22.25 16.5V18.75C22.25 19.5456 21.9339 20.3087 21.3713 20.8713C20.8087 21.4339 20.0456 21.75 19.25 21.75H5.75C4.95435 21.75 4.19129 21.4339 3.62868 20.8713C3.06607 20.3087 2.75 19.5456 2.75 18.75V16.5C2.75 16.3011 2.82902 16.1103 2.96967 15.9697C3.11032 15.829 3.30109 15.75 3.5 15.75Z' fill='white'/>
                                            </svg>
                                            <span>Download Template</span>
                                        </div>
                                    </Button>
                                </CardBody>
                            </Card>

                            <Card className='shadow mb-5'>
                                <CardHeader className='text-center'>
                                    <div>
                                        <div 
                                            className='d-flex justify-content-center align-items-center mx-auto mb-2'
                                            style={{
                                                width: 32,
                                                height: 32,
                                                borderRadius: 20,
                                                border: '1px solid #648EF7',
                                            }}><span>2</span></div>
                                        <h3 className='mb-0'>Upload Template File</h3>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                        }}
                                        onSubmit={onSubmit}
                                        validationSchema={validationSchema}
                                    >
                                        {propsChild => {
                                            const {
                                                values,
                                                // touched,
                                                // errors,
                                                // setFieldValue,
                                                // handleChange,
                                                handleSubmit,
                                            } = propsChild;
                                            return (
                                                <Form role='form' onSubmit={handleSubmit}>
                                                    <div>
                                                        <Row>
                                                            <Col md='6 m-auto'>
                                                                <FormGroup className='text-center'>
                                                                    {
                                                                        !csvFile.name && !csvFile.fileName? (
                                                                            <div className='file-upload'>
                                                                                <input 
                                                                                    type='file' 
                                                                                    ref={uploadFileEl}
                                                                                    accept='.csv' 
                                                                                    onChange={(e) => handleFileChange(e)} 
                                                                                    hidden />                
                                                                                <div className='progessBar' style={{ width: progress }}>
                                                                                </div>
                                                                                <Button
                                                                                    color='primary'
                                                                                    onClick={() => uploadFileEl.current.click()}
                                                                                >
                                                                                    <div className='d-flex justify-content-center align-items-center'>
                                                                                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                                                                            <path fillRule='evenodd' clipRule='evenodd' d='M11.47 2.46983C11.6106 2.32938 11.8012 2.25049 12 2.25049C12.1988 2.25049 12.3894 2.32938 12.53 2.46983L17.03 6.96983C17.1625 7.112 17.2346 7.30005 17.2312 7.49435C17.2277 7.68865 17.149 7.87404 17.0116 8.01145C16.8742 8.14886 16.6888 8.22758 16.4945 8.231C16.3002 8.23443 16.1122 8.16231 15.97 8.02983L12.75 4.80983V16.4998C12.75 16.6987 12.671 16.8895 12.5303 17.0302C12.3897 17.1708 12.1989 17.2498 12 17.2498C11.8011 17.2498 11.6103 17.1708 11.4697 17.0302C11.329 16.8895 11.25 16.6987 11.25 16.4998V4.80983L8.03 8.02983C7.88783 8.16231 7.69978 8.23443 7.50548 8.231C7.31118 8.22758 7.12579 8.14886 6.98838 8.01145C6.85097 7.87404 6.77225 7.68865 6.76883 7.49435C6.7654 7.30005 6.83752 7.112 6.97 6.96983L11.47 2.46983ZM3 15.7498C3.19891 15.7498 3.38968 15.8288 3.53033 15.9695C3.67098 16.1102 3.75 16.3009 3.75 16.4998V18.7498C3.75 19.1477 3.90804 19.5292 4.18934 19.8105C4.47064 20.0918 4.85218 20.2498 5.25 20.2498H18.75C19.1478 20.2498 19.5294 20.0918 19.8107 19.8105C20.092 19.5292 20.25 19.1477 20.25 18.7498V16.4998C20.25 16.3009 20.329 16.1102 20.4697 15.9695C20.6103 15.8288 20.8011 15.7498 21 15.7498C21.1989 15.7498 21.3897 15.8288 21.5303 15.9695C21.671 16.1102 21.75 16.3009 21.75 16.4998V18.7498C21.75 19.5455 21.4339 20.3085 20.8713 20.8711C20.3087 21.4338 19.5456 21.7498 18.75 21.7498H5.25C4.45435 21.7498 3.69129 21.4338 3.12868 20.8711C2.56607 20.3085 2.25 19.5455 2.25 18.7498V16.4998C2.25 16.3009 2.32902 16.1102 2.46967 15.9695C2.61032 15.8288 2.80109 15.7498 3 15.7498Z' fill='white'/>
                                                                                        </svg>
                                                                                        <span className='btn-inner--text'>Upload File</span>
                                                                                    </div>
                                                                                </Button>
                                                                            </div>
                                                                        ): (
                                                                            <Row>
                                                                                <Col md='8 pr-0'>
                                                                                    <Input
                                                                                        type='text'
                                                                                        value={csvFile.name || csvFile.fileName}
                                                                                        disabled
                                                                                    />
                                                                                </Col>
                                                                                {
                                                                                    values.source !== 'csv-prefix'? (
                                                                                        <Col md='4 pl-0'>
                                                                                            <Button
                                                                                                type='button'
                                                                                                color='danger'
                                                                                                onClick={() => setCsvFile({})}
                                                                                            >
                                                                                                Remove
                                                                                            </Button>
                                                                                        </Col>
                                                                                    ): null
                                                                                }
                                                                            </Row>
                                                                        )
                                                                    }
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    {/* <hr className="my-0" /> */}
                                                    <div className='text-center'>
                                                        <Button
                                                            className='my-4 text-white border-0'
                                                            style={{background: '#5E72E4'}}
                                                            type='submit'
                                                            disabled={loading}
                                                        >
                                                            Save Changes
                                                        </Button>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </CardBody>
                            </Card>

                            <Card className='shadow'>
                                <CardHeader className='text-center'>
                                    <div>
                                        <div 
                                            className='d-flex justify-content-center align-items-center mx-auto mb-2'
                                            style={{
                                                width: 32,
                                                height: 32,
                                                borderRadius: 20,
                                                border: '1px solid #648EF7',
                                            }}><span>3</span></div>
                                        <h3 className='mb-0'>Copy Link</h3>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className='text-center'>
                                        <p className='mb-0' style={{color: '#919BA7'}}>Preview Link (Copy this link to your website)</p>
                                        {
                                            data._id && (data.csvFile.fileName)? (
                                                <a href={`${window.location.protocol + '//' + window.location.host}/template/${data._id}`} target='_blank' rel='noopener noreferrer'>{window.location.protocol + '//' + window.location.host}/template/{data._id}</a>
                                            ): (
                                                <>
                                                    <a href={`${window.location.protocol + '//' + window.location.host}/template/default`} target='_blank' rel='noopener noreferrer'>{window.location.protocol + '//' + window.location.host}/template/default</a>
                                                    {/* <p>Please upload your file to get the link</p> */}
                                                </>
                                            )
                                        }
                                    </div>
                                </CardBody>
                            </Card>

                        </div>
                    </Col>
                </Row>
            </Container>
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};


const mapStateToProps = state => ({
    auth: state.role.auth,
    userId: state.role.details.user?state.role.details.user._id:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    companyInfo: state.company.data,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetting);