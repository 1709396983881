import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {connect} from 'react-redux';

import client from '../feathers.js';

// reactstrap components
import {
    Card,
    Container,
    Row,
    Table,
} from 'reactstrap';

const Plugin = (props) => {
    const { userId } = props;
    const [plugins, setPlugins] = useState([]);

    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then((auth)=>{
                    return client.service('plugin').find({
                        query: {
                            $limit: 100,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res)=>{
                    setPlugins(res.data);
                })
                .catch((err)=>{
                    console.log(err);
                });
        }
    }, [userId]);

    return (
        <>
            <Container fluid>
                <Row>
                    <div className='col'>
                        <Card className='shadow mt-6 mb-6'>
                            <div className='table-responsive mac-scrollbar'>
                                <Table className='align-items-center table-flush' style={{minHeight: 200}}>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Version</th>
                                            <th scope='col'>Updated Date</th>
                                            <th scope='col'>Description</th>
                                            <th scope='col'>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {plugins.map((x) => {
                                            return (
                                                <tr key={x._id}>
                                                    <td>
                                                        {x.version}
                                                    </td>
                                                    <td>
                                                        {dayjs(x.createdAt).format('DD-MMM-YYYY')}
                                                    </td>
                                                    <td>
                                                        <ul className='mb-0 pl-4'>
                                                            {x.description.map(x1 => {
                                                                return <li key={x1}>{x1}</li>;
                                                            })}
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <a href={x.downloadLink}>Download</a>
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};


const mapStateToProps = (state) => ({
    userId: state.role.details.user?state.role.details.user._id:'',
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Plugin);