/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

// reactstrap components
import {
  Label,
  Button,
  FormGroup,
  Input,
  Container,
} from "reactstrap";

import client from "../feathers"
import axios from "axios";

const Template = () => {
  const params = useParams();
  const [ data, setData ] = useState([])
  const [ keyIndex, setKeyIndex ] = useState(0)
  const [ fileIndex, setFileIndex ] = useState(0)
  const [ sizeIndex, setSizeIndex ] = useState(0)

  useEffect(() => {
    
    document.body.style.backgroundColor = "white";

    fetchTemplate()
  }, [])

  const fetchTemplate = () => {
    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}getCSVDownloadData`,
      {},
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {

      if(res.data.data.length > 0){
        res.data.data.shift() // remove titlke
  
        let getKeys = []
        // insert key
        res.data.data.map(x => {
          if(!getKeys.find(e => e.title === x[0])){
            getKeys.push({
              title: x[0],
              data: []
            })
          }
          return x
        })
        
        // insert file type
        res.data.data.map(x => {
          getKeys.map(x1 => {
            if(x1.title === x[0]){
              if(!x1.data.find(e => e.title === x[1])){
                x1.data.push({
                  title: x[1],
                  data: []
                })
              }
            }
            return x
          })
        })

        // insert the rest
        res.data.data.map(x => {
          getKeys.map(x1 => {
            x1.data.map(x2 => {
              if(x1.title === x[0] && x2.title === x[1]){
                if(!x2.data.find(e => e.downloadLink === x[3])){
                  x2.data.push({
                    title: x[2],
                    downloadLink: x[3]
                  })
                }
              }
            })
            return x
          })
        })
        
        setData(getKeys)
      }

    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (  
    <>
      <div className="header" style={{height: 64}}>
        <span className="mask" />
      </div>
      <Container className="mt-4">
        <FormGroup>
          <Label for="productSelect">
            Product
          </Label>
          <Input
            id="productSelect"
            name="select"
            type="select"
            onChange={(e) => {
              setKeyIndex(e.target.value)
              setFileIndex(0)
              setSizeIndex(0)
            }}
          >
            {
              data.length > 0 &&
              data.map((x, i) => {
                return (
                  <option key={i} value={i}>
                    {x.title}
                  </option>
                )
              })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="fileSelect">
            Supplier
          </Label>
          <Input
            id="fileSelect"
            name="select"
            type="select"
            onChange={(e) => {
              setFileIndex(e.target.value)
              setSizeIndex(0)
            }}
          >
            {
              data.length > 0 &&
              data[keyIndex]?.data.length > 0 && 
                data[keyIndex]?.data.map((x, i) => {
                  if(fileIndex === i){
                    return (
                      <option selected key={i} value={i}>
                        {x.title}
                      </option>
                    )  
                  }
                  return (
                    <option key={i} value={i}>
                      {x.title}
                    </option>
                  )
                })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="sizeSelect">
          Last Update
          </Label>
          <Input
            id="sizeSelect"
            name="select"
            type="select"
            onChange={(e) => {
              setSizeIndex(e.target.value)
            }}
          >
            {
              data.length > 0 &&
              data[keyIndex]?.data[fileIndex]?.data?.length > 0 && 
                data[keyIndex]?.data[fileIndex]?.data.map((x, i) => {
                  if(sizeIndex === i){
                    return (
                      <option selected key={i} value={i}>
                        {x.title}
                      </option>
                    )  
                  }
                  return (
                    <option key={i} value={i}>
                      {x.title}
                    </option>
                  )
                })
            }
          </Input>
        </FormGroup>
        {/* <FormGroup>
          <Label for="linkSelect">
            Download Link
          </Label>
          <Input
            id="linkSelect"
            type="text"
            defaultValue={
              data.length > 0? 
                data[selectIndex].data.length > 0?
                  data[selectIndex].data[typeIndex]?
                  data[selectIndex].data[typeIndex].link:
                  ''
                :''
              :''
            }
          />
        </FormGroup> */}
        <FormGroup className='text-center'>
          <Button 
            color='primary'
            href={
              data[keyIndex]?.data[fileIndex]?.data[sizeIndex]?.downloadLink
            }
            target="_blank"
            rel="noreferrer"
          >
            <div className="d-flex justify-content-center align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C12.1989 2.25 12.3897 2.32902 12.5303 2.46967C12.671 2.61032 12.75 2.80109 12.75 3V14.69L15.97 11.47C16.0387 11.3963 16.1215 11.3372 16.2135 11.2962C16.3055 11.2552 16.4048 11.2332 16.5055 11.2314C16.6062 11.2296 16.7062 11.2482 16.7996 11.2859C16.893 11.3236 16.9778 11.3797 17.049 11.451C17.1203 11.5222 17.1764 11.607 17.2141 11.7004C17.2518 11.7938 17.2704 11.8938 17.2686 11.9945C17.2668 12.0952 17.2448 12.1945 17.2038 12.2865C17.1628 12.3785 17.1037 12.4613 17.03 12.53L12.53 17.03C12.3894 17.1705 12.1988 17.2493 12 17.2493C11.8012 17.2493 11.6106 17.1705 11.47 17.03L6.97 12.53C6.89631 12.4613 6.83721 12.3785 6.79622 12.2865C6.75523 12.1945 6.73319 12.0952 6.73141 11.9945C6.72963 11.8938 6.74816 11.7938 6.78588 11.7004C6.8236 11.607 6.87974 11.5222 6.95096 11.451C7.02218 11.3797 7.10701 11.3236 7.2004 11.2859C7.29379 11.2482 7.39382 11.2296 7.49452 11.2314C7.59523 11.2332 7.69454 11.2552 7.78654 11.2962C7.87854 11.3372 7.96134 11.3963 8.03 11.47L11.25 14.69V3C11.25 2.80109 11.329 2.61032 11.4697 2.46967C11.6103 2.32902 11.8011 2.25 12 2.25ZM3 15.75C3.19891 15.75 3.38968 15.829 3.53033 15.9697C3.67098 16.1103 3.75 16.3011 3.75 16.5V18.75C3.75 19.1478 3.90804 19.5294 4.18934 19.8107C4.47064 20.092 4.85218 20.25 5.25 20.25H18.75C19.1478 20.25 19.5294 20.092 19.8107 19.8107C20.092 19.5294 20.25 19.1478 20.25 18.75V16.5C20.25 16.3011 20.329 16.1103 20.4697 15.9697C20.6103 15.829 20.8011 15.75 21 15.75C21.1989 15.75 21.3897 15.829 21.5303 15.9697C21.671 16.1103 21.75 16.3011 21.75 16.5V18.75C21.75 19.5456 21.4339 20.3087 20.8713 20.8713C20.3087 21.4339 19.5456 21.75 18.75 21.75H5.25C4.45435 21.75 3.69129 21.4339 3.12868 20.8713C2.56607 20.3087 2.25 19.5456 2.25 18.75V16.5C2.25 16.3011 2.32902 16.1103 2.46967 15.9697C2.61032 15.829 2.80109 15.75 3 15.75Z" fill="white"/>
              </svg>
              <span className="btn-inner--text">Download File</span>
            </div>
          </Button>
        </FormGroup>
      </Container>
    </>
  );
}
 
export default Template;